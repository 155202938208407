/*  ===============================================
    TopBar
------------------------*/
.top_bar {
	width: 100%;
	z-index: 3;
	height: 50px;
	line-height: 50px;
	.social-icons {
		padding: 0 8px;
		li {
			margin: 0;
			a {
				margin: 0;
				font-size: 16px;
				min-width: 35px;
			}
		}
		&:after {
			position: absolute;
			content: '';
			top: 0;
			height: 100%;
			width: 1px;
			left: 0;
			background-color: rgba(255, 255, 255, 0.08);
			z-index: 1;
		}
	}
}
.top_bar_icon {
	display: inline-block;
	vertical-align: middle;
	margin-right: 8px;
}
.top_bar_contact_item {
	font-size: 14px;
	height: 50px;
	line-height: 50px;
	position: relative;
}
.ttm-bgcolor-darkgrey {
	.top_bar_contact_item {
		.top_bar_icon {
			i {
				color: rgba(255,255,255,.37);
			}
		}
		color: rgba(255,255,255,.37);
	}
}


/*  ===============================================
    Header
------------------------*/
.top_bar .container-fluid,
.site-header-menu .container-fluid { padding: 0 100px; }
.ttm-header-style-02.header.is-Sticky .site-header-menu {
	z-index: 2;
	box-shadow: 0px 13px 25px -12px rgba(0, 0, 0, 0.15);
	position: fixed !important;
	top: 0;
	left: 0;
	display: table-cell;
	width: auto;
}










.header {
	.site-header-menu {
		.container-fluid { 
			padding: 0 40px;

			@media (max-width: 1199px){
				padding: 0 15px;
			}
		}

	}
	&.is-Sticky {
		.site-header-menu {
			z-index: 2;
			box-shadow: 0px 13px 25px -12px rgba(0, 0, 0, .15);
			position: fixed !important;
			top: 0;
			left: 0;
			width: 100%;
		}
	}
}
.ttm-header-style-03.is-Sticky .site-header-menu {
	background-color: rgba(0, 0 ,0 , 1) !important;
}

/* header_extra */
.header_extra {
	>div {
		margin-left: 18px;
		position: relative;
	}
}
.header_search_content {
	button.close-search {
		position: absolute;
		right: 15px;
		padding: 0;
		color: #fff;
		font-size: 21px;
		border-radius: 0;
		box-shadow: unset;
	}
}
.header_search a.search_btn {
	color: $theme-WhiteColor;
	font-size: 22px;
}
.header_search {
	.header_search_content {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		background: rgba(35,35,35,0.95);
		height: 100%;
		width: 100%;
		z-index: 10;
		transition: all .3s;
		#searchbox {
			position: absolute;
			left: 0;
			right: 0;
			width: 80%;
			max-width: 100%;
			height: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.search_query {
			background: none;
			border: 0;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			height: 70px;
			padding: 0 60px 0 0px;
			font-weight: 400;
			font-size: 18px;
			width: 100%;
			color: #fff;
			letter-spacing: 0;
			text-overflow: ellipsis;
			overflow: hidden;
			outline: none;
			background-color: transparent;
		}
		input {
			&::placeholder {
				color: #fff;
			}
		}
		.close_btn {
			i {
				font-size: 16px;
				font-weight: 600;
				color: #fff;
				-o-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				height: 35px;
				width: 35px;
				display: block;
				line-height: 35px;
				text-align: center;
				border-radius: 50%;
			}
			&:hover {
				i {
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
					-ms-transform: rotate(180deg);
					-o-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
		}
	}
	.header_search_content.on {
		visibility: visible;
		opacity: 1;
		display: block;
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
	}
	.header_search_content_inner {
		.close_btn {
			position: absolute;
			right: 60px;
			left: auto;
			top: 60px;
			color: #fff;
			cursor: pointer;
			text-align: center;
			border-radius: 50%;
			border: 1px solid;
		}
	}
}

/** SiteNavigation(Menu) **/
.site-navigation {
	position: relative;
	z-index: 2;
}
nav.main-menu {
	ul {
		position: relative;
		margin: 0;
	}
}
#site-header-menu {
	.site-navigation {
		ul.menu {
			>li {
				>a {
					font-weight: bold;
					font-family: $headingfont;
					color: $headingfont-Color;
					display: block;
					text-transform: capitalize;
					font-size: 16px;
					line-height: 1;
				}
			}
		}
	}
}
#site-header-menu .site-navigation ul.menu > li.active > a {
	color: $theme-SkinColor;
}
.ttm-topbar-wrapper {
	.ttm-social-links-wrapper {
		ul.social-icons li a:hover {
		color: $theme-WhiteColor;
		}
	}
}
.header_social {
	margin-left: 30px !important;
	&:before {
		position: absolute;
		content: "";
		height: 100%;
		width: 1px;
		top: 0;
		left: -10px;
		background-color: #e4ebff;
	}
}
@media only screen and (min-width: 1200px){ 

	/* header */
	.ttm-stickable-header.fixed-header {
		z-index: 4;
		background-color: #fff;
		box-shadow: 0px 13px 25px -12px rgba(0, 0, 0, .25);
	}
	.fixed-header {
		position: fixed !important;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 9;
	}
	#site-header-menu {
		.ttm-stickable-header.fixed-header {
			.site-navigation {
				ul.menu {
					>li {
						>a {
							padding: 33px 17px;
						}
					}
				}
			}
		}
	}
	// top-bar 
	/* ttm-header-style 01*/
	.ttm-header-style-01 {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 3;
		background-color: transparent;
		#site-header-menu {
			.site-navigation {
				ul.menu {
					>li {
						>a {
							padding: 42px 17px 42px 17px;
							position: relative;
						}
					}
					>li.active {
						>a {
							color: $theme-SkinColor;
						}
					}
				}
			}
		}
		.ttm-topbar-wrapper {
			font-weight: 700;
			padding-left: 70px;
			padding-right: 70px;
			height: 45px;
			line-height: 45px;
			ul.top-contact {
				li {
				color: rgba(255,255,255,.95);
				}
			}
			ul {
				li {
				display: inline-block;
				}
			}
			.top-contact {
				li:first-child {
					padding-right: 15px;
					margin-right: 15px;
				}
			}
			li {
				position: relative;
			}
		}
		ul.social-icons {
			font-size: 14px;
		}	
		ul.top-contact {
			li {
				i {
				font-size: 14px;;
				}
			}
		}
		.ttm-social-links-wrapper ul li:first-child::before {
			content: unset;
		}
		.site-header-menu.bg-theme-WhiteColor {
			padding-left: 50px;
			padding-right: 50px;
			@media (max-width: 1700px){
				padding-left: 0;
				padding-right: 0;
			}
		}
		.widget_info {
			.widget_icon {
			color: $theme-SkinColor;
			}
			.widget_content {
				margin-bottom: 0;
				font-weight: 600;
				color: rgba(0 , 0 , 0 , 0.70);
				padding-left: 20px;
				margin-right: 30px;
				span.widget_title {
					font-size: 16px;
					text-transform: capitalize;
				}
				h3.widget_number {
					font-size: 16px;
    				transition: all .3s ease;
				}
			}
		}
		.header_btn a {
			padding: 12px 20px;
		}
	}
}

	// top-bar 
	/* ttm-header-style 03*/
	.ttm-header-style-03 {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 3;
		background-color: transparent;
		ul.top-contact {
			display: flex;
		}
		#site-header-menu {
			background-color: rgba(0,0,0,0.63);
			.site-navigation {
				ul.menu {
					>li {
						>a {
							padding: 42px 17px 42px 17px;
							position: relative;
						}
					}
				}
			}
			.site-navigation ul.menu > li > a {
				color: $theme-WhiteColor;
			}
		}
		.social-icons {
			li:before {
				content: '';
				position: absolute;
				background-color: rgba(255 , 255 , 255 , 0.15);
				width: 1px;
				height: 18px;
				top: 15px;
				right: 0;
				left: auto;
			}
			li {
				padding-right: 15px;
				margin-right: 15px;
				float: left;
				text-align: center;
			}
		}
		.ttm-topbar-wrapper {
			height: 45px;
			line-height: 45px;
			ul.top-contact {
				li {
				color: rgba(255,255,255,.8);
				font-size: 15px;
				}
			}
			ul {
				li {
				display: inline-block;
				}
			}
			.social-icons {
				li a {
					color: rgba(255,255,255,1);
				}
				a:hover {
					color: $theme-SkinColor;
				}
				li:last-child:before {
					content: unset;
				}
			} 
			.top-contact {
				li:first-child {
					padding-right: 25px;
    				margin-right: 25px;
				}
			}
			li {
				position: relative;
			}
		}
		ul.social-icons {
			font-size: 14px;
		}	
		ul.top-contact {
			li {
				i {
				font-size: 14px;
				padding-right: 5px;
				color: $theme-SkinColor;
				}
			}
		}
		.widget_info {
			.widget_icon {
			color: $theme-SkinColor;
			}
			.widget_content {
				margin-bottom: 0;
				font-weight: 600;
				color: rgba(0 , 0 , 0 , 0.70);
				padding-left: 20px;
				margin-right: 30px;
				span.widget_title {
					font-size: 16px;
					text-transform: capitalize;
				}
				h3.widget_number {
					font-size: 16px;
    				transition: all .3s ease;
				}
			}
		}
		.ttm-widget_header {
			.header_btn {
				a {
					font-size: 16px;
					line-height: 23px;
					padding: 13px 20px;
				}
				a:hover {
					background-color: $theme-WhiteColor;
					color: $theme-DarkColor;
					border-color: $theme-WhiteColor;
				}
			}
			.ttm-btn:not(.btn-inline)::before {
				content: unset;
			}
		}
	}
	.section-title.style5 .title-desc p {
		display: none;
	}


/* ttm-header-style 02*/
.ttm-header-style-02 {
	position: absolute;
	left: 0;
	right: 0;
	z-index: 3;
	background-color: transparent;
	#site-header-menu {
		.site-navigation {
			ul.menu {
				>li {
					>a {
						padding: 42px 17px 42px 17px;
						position: relative;
					}
				}
				>li.active {
					>a {
						color: $theme-SkinColor;
					}
				}
			}
		}
	}
	.ttm-topbar-wrapper {
		padding-left: 70px;
		padding-right: 70px;
		height: 45px;
		line-height: 45px;
		ul.top-contact {
			li {
			color: rgba(255,255,255,.95);
			}
		}
		ul {
			li {
			display: inline-block;
			}
		}
		.top-contact {
			li:first-child {
				padding-right: 15px;
				margin-right: 15px;
			}
		}
		li {
			position: relative;
		}
	}
	ul.social-icons {
		font-size: 14px;
	}	
	ul.top-contact {
		li {
			i {
			font-size: 14px;;
			}
		}
	}
	.ttm-social-links-wrapper ul li:first-child::before {
		content: unset;
	}
	.site-header-menu.bg-theme-WhiteColor {
		padding-left: 50px;
		padding-right: 50px;
		@media (max-width: 1700px){
			padding-left: 0;
			padding-right: 0;
		}
	}
	.widget_info {
		.widget_icon {
		color: $theme-SkinColor;
		}
		.widget_content {
			margin-bottom: 0;
			font-weight: 600;
			color: rgba(0 , 0 , 0 , 0.70);
			padding-left: 20px;
			margin-right: 30px;
			span.widget_title {
				font-size: 16px;
				text-transform: capitalize;
			}
			h3.widget_number {
				font-size: 16px;
				transition: all .3s ease;
			}
		}
	}
	.header_btn a {
		padding: 12px 20px;
	}
	
}


@media only screen and (min-width: 1200px){

/* ttm-header-style 03*/
.ttm-header-style-02 #site-header-menu .site-navigation ul.menu > li >a {
	padding: 28px 18px 27px;
}
.ttm-header-style-02 #site-header-menu .site-navigation ul.menu > li >a {
	font-weight: 700;
}
.ttm-header-style-02 nav.main-menu ul.menu > li > a{
	color: $theme-WhiteColor;
}
.ttm-header-style-02 #site-header-menu .site-navigation ul.menu > li > a:hover {
	color: $theme-SkinColor;
}
.ttm-header-style-02 .site-navigation:before {
	position: absolute;
	content: "";
	right: 0;
	top: 0;
	width: 5000px;
	height: 80px;
}
.ttm-header-style-02 #site-header-menu {
	padding-left: 70px;
    padding-right: 40px;
    height: 100px;
    line-height: 100px;
	display: table-cell;
}
.ttm-header-style-02 #site-header-menu .site-navigation ul.menu > li > a {
	color: $theme-WhiteColor;
}
.ttm-header-style-02 .d-flex.flex-row {
	display: table-cell;
    position: relative;
}
.ttm-header-style-02 .main-menu {
	padding-left: 100px;
}
.ttm-header-style-02 .site-header-menu ul.menu:before {
    position: absolute;
    content: '';
    background-color: rgba(255 , 255 , 255 , 0.30);
    height: 40px;
    width: 1px;
    top: 30px;
    left: -35px;
}
.ttm-header-style-02 .header_search .btn-default {
	background-color: transparent !important;
}
.ttm-header-style-02 .header_search .btn-default i {
	font-size: 22px;
}
.ttm-header-style-02 .widget_info {
    position: relative;
    vertical-align: middle;
    display: table;
    padding: 2px 0px 25px 25px;
}
.ttm-header-style-02 .widget_info .widget_icon {
    float: none;
    font-size: 40px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}
.ttm-header-style-02 .header_extra .widget_icon i {
    position: relative;
    top: 15px;
	color: $theme-SkinColor;
}
.ttm-header-style-02 .widget_info .widget_content {
    margin-left: 14px;
    position: relative;
    top: 10px;
}
.ttm-header-style-02 .widget_info .widget_content h3 {
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #fff;
}
.ttm-header-style-02 .widget_info .widget_content h3 a {
	color: $theme-WhiteColor;
}
.ttm-header-style-02 .widget_info .widget_content p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #fff;
	margin-bottom: 0;
}
.ttm-header-style-02 .widget_info .widget_content p a {
    color: #fff;
}
.ttm-header-style-02 .header_search:before {
	content: '';
    position: absolute;
    background-color: hsla(0,0%,100%,.3);
    width: 1px;
    height: 40px;
    top: 30px;
    left: 39px;
}
.ttm-header-style-02 #menu-toggle {
	z-index: 999;
    background-color: $theme-SkinColor;
    height: 100px;
    width: 100px;
    -webkit-transition: all 200ms linear 0ms;
    -khtml-transition: all 200ms linear 0ms;
    -moz-transition: all 200ms linear 0ms;
    -ms-transition: all 200ms linear 0ms;
    -o-transition: all 200ms linear 0ms;
    transition: all 200ms linear 0ms;
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
	display: block;
    top: 0px;
    position: absolute;
    left: auto;
    right: 0;
}
.ttm-header-style-02 .prt-equal-height-image, .preyantechnosys-hide, .prt-hide {
    display: none;
}

}
@media only screen and (max-width: 1400px) {
	.ttm-header-style-02 #site-header-menu {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
}

	// @media only screen and (min-width: 1200px) {
	// 	header.ttm-header-style-02 #site-header-menu {
	// 		color: tan;
	// 	}
	// 	.ttm-header-style-02 {
	// 		.ttm-stickable-header {
	// 			position: fixed !important;
	// 			top: 0;
	// 			left: 0;
	// 			width: 100%;
	// 			z-index: 111;
	// 		}
	// 		.ttm-stickable-header {
	// 			z-index: 111;
	// 			background-color: rgba(0,0,0,0.69);
				
	// 		}
	// 		.site-branding {
	// 			padding: 20px 30px 27px 35px;
	// 			position: fixed;
	// 			top: 0;
	// 			left: 0px;
	// 			width: 272px;
	// 			height: 92px;
	// 			background-color: $theme-DarkColor;
	// 			z-index: 3;
	// 			transition: all 0.3s ease-in-out;
	// 			-moz-transition: all 0.3s ease-in-out;
	// 			-webkit-transition: all 0.3s ease-in-out;
	// 			-o-transition: all 0.3s ease-in-out;
	// 		}
	// 		.site-branding:before {
	// 			left: auto;
	// 			right: 100%;
	// 			width: 1200px;
	// 			background-color: $theme-DarkColor;
	// 		}
	// 		.site-branding:before {
	// 			content: "";
	// 			position: absolute;
	// 			left: 100%;
	// 			top: 0;
	// 			width: 100px;
	// 			height: 100%;
	// 			margin-right: 0px;
	// 			margin-left: 0px;
	// 			background-color: $theme-SkinColor;
	// 		}
			
	// 	}
	// }
	// @media only screen and (max-width: 1200px) {
	// 	header.ttm-header-style-02 .menubar {
	// 		left: auto !important;
	// 		right: 0;
	// 		top: 0 !important;
	// 	}
	// }
	// @media (max-width: 1920px) {
	// 	header.ttm-header-style-02 {
	// 		.menubar {
	// 			position: absolute;
	// 			left: 15%;
	// 			top: 45px;
	// 			bottom: 0;
	// 			z-index: 9;
	// 			cursor: pointer;
	// 		}
	// 		.menubar-box {
	// 			display: block;
	// 			width: 30px;
	// 			height: 24px;
	// 		}
	// 		.menubar--squeeze .menubar-inner {
	// 			top: 50%;
	// 			display: block;
	// 			margin-top: -2px;
	// 			transition-timing-function: cubic-bezier(.55,.055,.675,.19);
	// 			transition-duration: .1s;
	// 		}
	// 		.menubar-inner:before {
	// 			top: -8px;
	// 		}
	// 		.menubar-inner:after, .menubar-inner:before {
	// 			display: block;
	// 			content: '';
	// 		}
	// 		.menubar-inner, .menubar-inner:after, .menubar-inner:before {
	// 			position: absolute;
	// 			width: 28px;
	// 			height: 2px;
	// 			transition-timing-function: ease;
	// 			transition-duration: .15s;
	// 			transition-property: transform;
	// 			border-radius: 4px;
	// 			background-color: #fff;
	// 		}
	// 		.menubar-inner:after {
	// 			bottom: -8px;
	// 		}
	// 		.menubar-inner:after, .menubar-inner:before {
	// 			display: block;
	// 			content: '';
	// 		}
	// 		nav.main-menu ul.menu, nav.main-menu ul.menu > li {
	// 			display: inline-block;
	// 		}
	// 		.menubar--squeeze.is-active .menubar-inner {
	// 			transition-delay: .14s;
	// 			transition-timing-function: cubic-bezier(.215,.61,.355,1);
	// 			transform: rotate(45deg);
	// 		}
	// 		.menubar--squeeze.is-active .menubar-inner:before {
	// 			top: 0;
	// 			transition: top .1s ease,opacity .1s ease .14s;
	// 			opacity: 0;
	// 		}
	// 		.menubar--squeeze.is-active .menubar-inner:after {
	// 			bottom: 0;
	// 			transition: bottom .1s ease,transform .1s cubic-bezier(.215,.61,.355,1) .14s;
	// 			transform: rotate(-90deg);
	// 		}
	// 	} 
		
	// }
	// @media only screen and (max-width: 1400px) {
	// 	.ttm-header-style-01 .site-header-menu.bg-theme-WhiteColor {
	// 		padding-left: 0px;
	// 		padding-right: 0px;
	// 	}
	// 	.ttm-header-style-01 .ttm-topbar-wrapper {
	// 		padding-left: 30px;
    // 		padding-right: 30px;
	// 	}
	// }
	
/*  ===============================================
    Slick_dots/arrows
------------------------*/
.slick-slide {
	border: 0;
	outline: 0;
}
.slick_slider.slick-dots-style1 {
	.slick-dots li {
		width: 50px;
		line-height: 50px;
		margin-right: 40px;
		position: relative;
	}
	.slick-dots li button {
		font-size: 20px;
		background: 0 0;
		color: $theme-DarkColor;
		height: 50px;
		width: 50px;
		line-height: 40px;
		border: 1px solid #010b14;
		display: inline-block;
		border-radius: 50px;
		box-shadow: none;
		cursor: pointer;
		margin: 13px 10px 0 0;
		padding: 0;
		position: relative;
		@media (max-width: 375px){
			margin-top: 20px;
		}
	}
	.slick-dots li:not(:last-child):after {
		content: '';
		position: absolute;
		top: auto;
		bottom: -20px;
		left: auto;
		right: -40px;
		height: 1px;
		width: 35px;
		background-color: #010b14;
	}
	li button:before {
		content: unset;
	}
	.slick-dots {
		left: 138px;
		bottom: 0;
		@media (max-width: 991px) {
			left: 0;
		}
	}
	.slick-dots li.slick-active:after {
		background-color: $theme-SkinColor;
	}
	.slick-dots li.slick-active button {
		border-color: $theme-SkinColor;
		background-color: $theme-SkinColor;
		color: $theme-WhiteColor;
	}
}

.slick_slider {
	.slick-arrow {
		width: 40px;
		height: 40px;
		z-index: 1;
		border: 0;
		color: inherit;
		background-color: rgba(255,255,255,.07);
		box-shadow: 0 0 10px 0 rgba(0, 43, 92, 0.08);
		border: 0;
	}
	.slick-next {
		right: 0;
		&:before {
			font-family: 'themify';
			font-size: 15px;
			line-height: 1;
			opacity: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\e628";
		}
	}
	.slick-prev {
		&:before {
			font-family: 'themify';
			font-size: 15px;
			line-height: 1;
			opacity: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\e629";
		}
	}
}
.slick_slider.slick-arrows-style1 {
	.slick-arrow {
		border: 2px solid $white;
		border-radius: 50%;
		opacity: 0;
		z-index: 11;
		overflow: hidden;
		box-shadow: unset;
	}
	.slick-next {
		right: 0px;
		left: auto;
	}
	.slick-prev {
		left: 0px;
	}
	&:hover {
		.slick-arrow {
			margin: 0 -60px;
			opacity: 1;
			overflow: visible;
		}
	}
	.slick-arrow:hover {
		background-color: $theme-WhiteColor;
	}
	.slick-prev:hover:before {
		color: $theme-SkinColor;
	}
	.slick-next:hover:before {
		color: $theme-SkinColor;
	}
}

.slick_slider.slick-arrows-style2 {
	.slick-arrow {
		border-radius: 50%;
	}
	.slick-next {
		right: -50px;
		left: auto;
	}
	.slick-prev {
		left: -50px;
	}
}
.slick_slider.slick-arrows-style3 {
	.slick-next {
		font-size: 0;
		line-height: 0;
		position: absolute;
		bottom: 20px;
		width: 45px;
		height: 45px;
		padding: 0;
		transform: translate(0, -50%);
		color: inherit;
		background-color: transparent;
		border: 2px solid #e6e6e6;
		box-shadow: unset;
		border-radius: 50%;
		right: 0px;
		top: 15%;
	}
	.slick-prev {
		font-size: 0;
		line-height: 0;
		position: absolute;
		bottom: 20px;
		width: 45px;
		height: 45px;
		padding: 0;
		transform: translate(0, -50%);
		color: inherit;
		background-color: transparent;
		border: 2px solid #e6e6e6;
		box-shadow: unset;
		border-radius: 50%;
		right: 50px;
		top: 15%;
	}
}
.slick-prev {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
}
.slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
}

