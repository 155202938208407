.footer {
	z-index: 1;
	background-position: center center;
	background-attachment: scroll;
    color: rgba(255, 255, 255, 0.70);
	.footer-logo {
		img {
			max-height: 45px;
		}
	}
	.widget {
		.widget-title {
			font-weight: 700;
			font-size: 22px;
			line-height: 35px;
			margin-bottom: 35px;
			display: inline-block;
			position: relative;
			color:$white;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
    }
}
.first-footer p, .first-footer span, .first-footer a {
    color: rgba(255,255,255,.85);
}
/** ttm-recent-post-list **/
.widget-area .widget ul.ttm-recent-post-list {
    >li {
        display: table;
        padding-top: 0;
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid rgba(255,255,255,.08);
        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: 0;
        }
        >.post-detail {
            a {
                font-size: 16px;
                line-height: 30px;
                display: block;
                font-weight: 400;
                position: relative;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            >.cat-link {
                a {
                    font-size: 13px;
                    line-height: 1;
                    display: inline-block;
                    margin-bottom: 2px;
                    color: $theme-SkinColor;
                    border-bottom: 1px solid;
                    text-transform: uppercase;
                }
            }
        }
    }
}
.footer-logo {
	margin-bottom: 30px;
}

/* first-footer */
.first-footer {
	.container {
		position: relative;
		&:before {
			position: absolute;
			content: "";
			left: 15px;
			right: 15px;
			bottom: 0;
			border-bottom: 1px solid rgba(255,255,255,.06);
		}
	}
	.widget-area {
		.widget {
			padding:60px 0 55px;
			@media (max-width: 991px) {
				padding: 30px 0 30px;
			}
		}
		.widget-contact {
			background-color: #101700;
			padding: 30px;
			margin-top: 50px;
		}
	}
	.widgrt-box-main {
		background-color: #061019;
		padding: 35px 30px 28px;
		border-radius: 8px;
	}
}

/** widget_nav_menu **/

.widget_nav_menu ul li {
	padding-bottom: 6px;
}
.widget_nav_menu ul li a:hover {
	color: $theme-SkinColor;
}

/** widget_contact_wrapper **/
ul.widget_contact_wrapper {
	padding-top: 10px !important;
	li {
		padding-left: 60px;
		position: relative;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid rgba(255,255,255,.06);
		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: 0;
		}
		i {
			position: absolute;
			left: 0;
			top: 2px;
			line-height: 1;
			width: 1em;
            color: $theme-SkinColor;
			text-align: center;
			font-size: 40px;
			font-weight: 400;
		}
		h3 {
			font-size: 18px;
			line-height: 1;
			margin-bottom: 5px;
		}
	}
}

/** Footer-nav-menu **/
.footer-nav-menu {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		padding-left: 2px;
		padding-right: 16px;
		font-size: 16px;
		font-weight: normal;
		padding-bottom: 0;
		
        a {
            color: #cbcbcb !important;
            &:hover {
                color: $theme-SkinColor !important;
            }
        }
        
		&:last-child {
			padding-right: 0;
		}
		&:first-child {
			padding-left: 0;
		}
	}
}
ul.footer-nav-menu {
	li {
		&:not(:last-child) {
			&:after {
				position: absolute;
				content: "|";
				color: #fff;
				padding-left: 7px;
			}
		}
	}
}
.bottom-footer-text {
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: transparent;
	background-position: center center;
	background-size: auto;
	background-repeat: no-repeat;
	background-attachment: fixed;
	ul li a {
		font-size: 14px;
		color: rgba(255,255,255,.7);
	}
}

/** widfet-timing **/

.first-footer  {
	.widget-timing {
		h3 a {
			font-size: 20px;
			margin-bottom: 0;
			padding-top: 33px;
		}
		p a {
			font-size: 18px;
			border-bottom: 1px solid;
			color: $theme-SkinColor;
		}
	}
}


/** copyright **/
.copyright {
	display: block;
	font-size: 14px;
	line-height: 1;
	margin-top: 5px;
	margin-bottom: 7px;
	color: #cbcbcb !important;
	a {
		color: #cbcbcb;
		&:hover {
			color: $theme-SkinColor;
		}
	}
}
.subscribe-form {
	padding-bottom: 10px;
	width: 100%;
	position: relative;
	border-bottom: 5px solid rgba(255,255,255,.04);
	input[type="email"] {
		background-color: transparent;
		padding: 0;
		border: 0;
		width: 100%;
        color: $theme-WhiteColor;
		padding-right: 40px;
		box-shadow: unset;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	
        &::placeholder  {
            color: #a3a3a3;
        }
    }
	button.submit {
		padding: 0;
		border: 0;
		position: absolute;
		right: 0;
		top: -2px;

        i {
            font-size: 16px;
            line-height: 1;
			color: $theme-SkinColor;
        }
	}
}
#comment-cookies-consent {
	margin-right: 10px;
	margin-top: 30px;
}

/** second-footer **/

.second-footer {
	.footer-box {
		padding: 30px 0 20px;
	}
	.social-icons {
		ul {
			li {
				margin-right: 20px;
				font-size: 15px;
				padding: 5px 0 5px;
				position: relative;
				a {
					width: auto;
					height: auto;
					line-height: initial;
					border-radius: 50% !important;
					border: 0 solid rgba(255,255,255,.1);
					text-align: center;
					display: block;
					margin: 0;
					font-size: 15px;
					color: rgba(255,255,255,1);
					background: 0 0 !important;
					text-transform: capitalize;
					font-weight: 700;
				}
			}
		}
		i {
			font-size: 14px;
			border: 1px solid #fff;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			display: inline-block;
			text-align: center;
			line-height: 35px;
			margin-right: 17px;
			transition: .5s ease-in-out;
		}

	}
	.social-icons ul li a:hover i {
		background-color: $theme-WhiteColor;
		border-color: #fff;
		color: $theme-SkinColor;
	}
}

