@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');

body {
  color: $bodyfont-Color;
  font-family: $bodyfont;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
  
  .page {
    overflow: hidden;
    position: relative;
    z-index: 10;
  }
}
.container {
  position: relative;
  max-width: 1330px;
}
/* General */

::selection {
  background: $theme-SkinColor;
  color: $white;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

a {
  transition: 0.4s;
  color: $theme-DarkColor;
  outline: none;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $theme-SkinColor;
    outline: none;
    text-decoration: none;
  }
}

textarea, input {
  &:focus, &:active {
    outline: none;
  }
}

/* Heading Default-Style */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 600;
  font-family: $bodyfont;
  color: $headingfont-Color;
  line-height: 1.2;
}

h1, .h1 {
  font-weight: 700;
  margin-bottom: 25px;
}

h2, .h2 {
  font-weight: 600;
  margin-top: -5px;
  margin-bottom: 18px;
}

h3, .h3 {
  margin-bottom: 10px;
}

h4, .h4 {
  font-size: 30px;
}

h5, .h5 {
  font-size: 20px;
}

h6, .h6 {
  font-size: 16px;
}

/* Paragraph Margin */
p {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

strong, b {
  font-weight: 700;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  box-shadow: none;
  transition: 0.4s;

  &:active, &:focus {
    outline: none;
    box-shadow: none;
  }
}
.row:not(.g-0) {
  margin-left: -15px;
  margin-right: -15px;
}
.border-radius-8 { border-radius: 8px;}
.fs-52 { font-size: 52px;}
.fs-36 {font-size: 36px; }
.fs-24 {font-size: 24px;}
.fs-20 {font-size: 20px;}

.fw-700 { font-weight: 700;}

.border-left { border-left: 1px solid #CCCCCC66;}
.border-left-0 { 
  @media (max-width: 991px){
		border-left: 0;
	} 
}
.right {
  right:50px;
  @media (max-width: 991px){
		right:0;
	}
}
.right-140 {
  right:140px !important;
}

.z-index-99 { z-index: 99 !important;}
.z-index-0 { z-index: 0 !important;}
.z-index-1 { z-index: 1 !important;}
.z-index-2 { z-index: 2 !important;}

.container-fluid{padding: 0 15px;}
.row:not(.g-0) {
    margin-left: -15px;
    margin-right: -15px;
}
.row:not(.g-0) > [class*='col-'] {
    padding-left: 15px;
    padding-right: 15px;
}

.text-theme-WhiteColor {
	h1:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
	h2:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
	h3:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
	h4:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
	h5:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
	h6:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
  a {
    &:not(.text-theme-SkinColor) {
      color: $theme-WhiteColor;
    }
    &:hover {
      color: $theme-SkinColor;
    }
  }
	p:not(.text-theme-SkinColor) {
		color: rgba(255, 255, 255, 0.70);
	}
  li:not(.text-theme-SkinColor) {
		color: rgba(255, 255, 255, 0.70);
	}
	span:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
}
