@font-face {
    font-family: "flaticon";
    src: url("../../fonts/flaticon.ttf?990556c59ed2b8a765a80a6838c9dec8") format("truetype"),
url("../../fonts/flaticon.woff?990556c59ed2b8a765a80a6838c9dec8") format("woff"),
url("../../fonts/flaticon.woff2?990556c59ed2b8a765a80a6838c9dec8") format("woff2"),
url("../../fonts/flaticon.eot?990556c59ed2b8a765a80a6838c9dec8#iefix") format("embedded-opentype"),
url("../../fonts/flaticon.svg?990556c59ed2b8a765a80a6838c9dec8#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-facemask:before {
    content: "\f101";
}
.flaticon-virus:before {
    content: "\f102";
}
.flaticon-up-right-arrow:before {
    content: "\f103";
}
.flaticon-blanket:before {
    content: "\f104";
}
.flaticon-dress:before {
    content: "\f105";
}
.flaticon-iron:before {
    content: "\f106";
}
.flaticon-curtains:before {
    content: "\f107";
}
.flaticon-towel:before {
    content: "\f108";
}
.flaticon-phone-call:before {
    content: "\f109";
}
.flaticon-bubble-chat:before {
    content: "\f10a";
}
.flaticon-steam-iron:before {
    content: "\f10b";
}
.flaticon-drying:before {
    content: "\f10c";
}
.flaticon-laundry:before {
    content: "\f10d";
}
.flaticon-washing-clothes:before {
    content: "\f10e";
}
.flaticon-menu:before {
    content: "\f10f";
}
.flaticon-close:before {
    content: "\f110";
}
.flaticon-loupe:before {
    content: "\f111";
}
.flaticon-double-arrow:before {
    content: "\f112";
}
.flaticon-arrow:before {
    content: "\f113";
}
.flaticon-right-arrow:before {
    content: "\f114";
}
.flaticon-up-right-arrow-1:before {
    content: "\f115";
}
.flaticon-support:before {
    content: "\f116";
}
.flaticon-24-hours-support:before {
    content: "\f117";
}
.flaticon-offer:before {
    content: "\f118";
}
.flaticon-business-partnership:before {
    content: "\f119";
}
.flaticon-help:before {
    content: "\f11a";
}
.flaticon-team:before {
    content: "\f11b";
}
.flaticon-left-quote:before {
    content: "\f11c";
}
.flaticon-laundry-1:before {
    content: "\f11d";
}
.flaticon-palm-islands:before {
    content: "\f11e";
}
.flaticon-toronto:before {
    content: "\f11f";
}
.flaticon-mission:before {
    content: "\f120";
}
.flaticon-focus:before {
    content: "\f121";
}
.flaticon-history:before {
    content: "\f122";
}
.flaticon-london-eye:before {
    content: "\f123";
}
.flaticon-melbourne:before {
    content: "\f124";
}
.flaticon-laundry-2:before {
    content: "\f125";
}
.flaticon-booking:before {
    content: "\f126";
}
.flaticon-washing-machine:before {
    content: "\f127";
}
.flaticon-laundry-basket:before {
    content: "\f128";
}
.flaticon-location:before {
    content: "\f129";
}
.flaticon-email:before {
    content: "\f12a";
}
.flaticon-support-1:before {
    content: "\f12b";
}
.flaticon-operator:before {
    content: "\f12c";
}
.flaticon-cancel:before {
    content: "\f12d";
}
.flaticon-read:before {
    content: "\f12e";
}
.flaticon-steering-wheel:before {
    content: "\f12f";
}
.flaticon-scroll-bar:before {
    content: "\f130";
}
.flaticon-car-wash:before {
    content: "\f131";
}
.flaticon-car-service:before {
    content: "\f132";
}
.flaticon-deal:before {
    content: "\f133";
}
.flaticon-file:before {
    content: "\f134";
}
.flaticon-play:before {
    content: "\f135";
}

