//== Typography Variable
$bodyfont: "Syne", Tahoma, Geneva, sans-serif;
$bodyfont-Size: 16px;
$headingfont: "Syne", Tahoma, Geneva, sans-serif;

//== Theme Typo Colors
$bodyfont-Color: #666666;
$headingfont-Color: #000000;
$border-Color: #c4c4c4;

//== Theme Color
$theme-SkinColor: #00ADEF;
$theme-DarkColor: #102342;
$theme-GreyColor: #f3f7fb;
$theme-WhiteColor: #FFFFFF;


//== Ex Color
$white: #FFFFFF;
$black: #000000;

//== Social Media Icons Color
$facebook: #3B5999;
$twitter: #1DA1F2;
$pinterest: #CB2028;
$youtube: #CC332D;
$reddit: #FF4500;
$linkedin: #0077B5;

//== root


// Background Colors
.bg {

    &-theme-SkinColor {
      background-color: $theme-SkinColor;
    }
  
    &-theme-DarkColor {
      background-color: $theme-DarkColor;
    }
  
    &-theme-GreyColor {
      background-color: $theme-GreyColor;
    }

    &-theme-WhiteColor {
      background-color: $theme-WhiteColor;
    }

  }
  
  // Text Colors
  .text {

    &-theme-SkinColor {
      color: $theme-SkinColor;
    }
  
    &-theme-DarkColor {
      color: $theme-DarkColor;
    }
  
    &-theme-GreyColor {
      color: $theme-GreyColor;
    }

    &-theme-WhiteColor {
      color: $theme-WhiteColor;
    }

  } 
  