
/** 
          1. ttm-row 

          2. Row-Equal-Height

          3. Bg-Layer 

          4. Row-Bg-Image

          5. Col-Bg-Image

          6. Section-Title 

          7. sep_holder

          8. Buttons

          9. Icons

          10. Fid

          11. featured-icon-box

          12. Featured-imagebox

          13. Progress-Bar 

          14. Testimonial

          15. Client-row

          16. Accordion

          17. Wrap-Form

          18. Tab

          19. Boxes-Spacing

          20. Sidebar

**/



/** .inner-rect-bar { background-color: $skin-color !important; } **/

/* ===============================================
    1.ttm-row
------------------------*/

/* ===============================================
    3.Bg-Layer 
------------------------*/
/* ===============================================
    4.Row-Bg-Image  
------------------------*/
/* ===============================================
    5.Col-Bg-Image  
------------------------*/
/* ===============================================
    6.Section-Title  
------------------------*/
/* style2 */
/* ===============================================
    7.sep_holder
------------------------*/
/* ===============================================
    8.Buttons
------------------------*/
/** btn-with-icon **/
/** btn-size-xs **/
/** btn-size-sm **/
/** btn-size-md **/
/** btn-size-lg **/
/** btn-shape **/
/** btn-style-border **/
/** btn-inline **/
/* play-btn / play-icon */

/* ===============================================
    9.Icons
------------------------*/
/** icon-size-xs **/
/** icon-size-sm **/
/** icon-size-md **/
/** icon-size-lg **/
/** icon-size-xl **/
/** icon-shape **/
/* ttm-list-style-icon */
/* ttm-video-icon */
/* ===============================================
    10.Fid
------------------------*/
/* ===============================================
    11.featured-icon-box ( only contents )
------------------------*/
@keyframes ttm-bounce {
	0% {
		transform: (0, 0, 0);
	}
	40% {
		transform: (0, -12px, 0);
	}
	100% {
		transform: (0, 0, 0);
	}
}
/* style1 */
/* style2 */
/* style3 */
@keyframes rotate {
	0.00% {
		transform: translateY(-6px) translateX(0px);
	}
	33.33% {
		transform: translateY(3px) translateX(-3px);
	}
	50.00% {
		transform: translateY(4px) translateX(-4px);
	}
	66.67% {
		transform: translateY(3px) translateX(-3px);
	}
	100.00% {
		transform: translateY(-6px) translateX(0px);
	}
}
/* style4 */
/* style5 */
/* style6 */
/* style7 */
/* style8 */
/* style9 */
/* style10 */
/* style11 */
/* ===============================================
    12.featured-imagebox ( contents with image)
------------------------*/
/* post */
/* post-1*/
/* post-2 */
/* services-01*/
/* services-02*/
/* style1 */
/* portfolio2 */
/* team-1 */
/* team-2 */
/* ===============================================
    13.Progress-Bar
------------------------*/
/* ===============================================
    14.Testimonial
------------------------*/
/* ttm-testimonial-box-view-style1 */
/* ttm-testimonial-box-view-style2 */
/* ===============================================
    15.Client-row  
------------------------*/
/* ===============================================
    16.Accordion
------------------------*/
/* ttm-style-classic */
/* ===============================================
    17.Wrap-Form
------------------------*/
/* contactform */
/* ===============================================
    18.Tab
------------------------*/
/* ttm-tab-style-01 */
/* ttm-tab-style-02 */
/* ttm-tab-style-vertical */
/* ===============================================
    19.Boxes-Spacing
------------------------*/
/* ===============================================
    15.Pricing-Plan
------------------------*/
/* ===============================================
    21.Sidebar
------------------------*/
/*widget-search*/
/* contact-widget */
/* widget-banner */
/* widget-download */
/* widget-categories */
/* widget-nav-menu */
/** ttm-recent-post-list **/
/* tagcloud */
/* pagination */


.ttm-row { 
	padding: 100px 0; 
	@media (max-width: 991px){
		padding: 60px 0;
	}
}
.ttm-row.padding_bottom_zero-section { 
	padding: 100px 0 0; 
	@media (max-width: 991px){
		padding: 60px 0 0;
	}
}
.ttm-row.padding_top_zero-section {
	padding: 0 0 100px 0; 
	@media (max-width: 991px){
		padding: 0 0 60px 0;
	}
}
.ttm-row.padding_zero-section {	
	padding: 0 0; 
	@media (max-width: 991px){
		padding:  0;
	}
}
.ttm-row.broken-section { padding: 0 0; }
.ttm-row.services-section-1 { 
	padding: 80px 0 110px; 
	@media (max-width: 991px){
		padding: 60px 0;
	}
}
.ttm-row.fid-section {
	padding: 100px 0 60px;
	@media (max-width: 991px){
		padding: 60px 0 20px;
	}
}
.ttm-row.contact_2_section { padding: 100px 0 75px;}
.ttm-row.team-section {	padding: 90px 0 85px; }
.ttm-row.grid-section {	
	padding: 75px 0;
	@media (max-width: 991px){
		padding: 40px 0 40px;
	}
}
.ttm-row.sidebar { padding: 80px 0; }
.ttm-row.client-section { padding: 15px 0 5px; }
.ttm-row.faq-section { padding: 0 0 100px; }
.ttm-row.request-section { padding: 0 0 0; }
.ttm-row.team-section_1 {
	padding: 100px 0 65px;
	@media (max-width: 991px){
		padding: 50px 0 25px;
	}
}

.ttm-row.testimonial-section { 
	padding: 200px 0 0px; 
	@media (max-width: 991px){
		padding-top: 60px;
	}
}
.ttm-row.faq-section-1 { 
	padding: 0 0 0; 
	@media (max-width: 991px){
		padding: 60px 0 60px;
		margin-top: 0;
	}
}
.ttm-row.team-section {
	padding: 100px 0 0px; 
	@media (max-width: 991px){
		padding: 0px 0 60px;
	}
}
.ttm-row.marque-section { padding: 50px 0 30px; }
.ttm-row.marque-section_1 { padding: 50px 0;}
.ttm-row.marque-section_2 { padding: 80px 0;}
.ttm-row.services-section-2 { 
	padding: 75px 0 53px; 
	@media (max-width: 991px){
		padding: 10px 0 60px;
	}
}
.ttm-row.blog-section { 
	padding: 100px 0 75px;
	@media (max-width: 991px){
		padding: 60px 0 45px;
	}
}
.ttm-row.team-details-section {
	padding: 0px 0 70px;
}
.ttm-row.faq-section-2 {
	padding: 50px 0;
	@media (max-width: 991px){
		padding: 35px 0 30px;
	}
}
.ttm-row.faq-section-3 {
	padding: 100px 0 90px;
	@media (max-width: 991px){
		padding: 60px 0 30px;
	}
}
.ttm-row.services-section-3 {
	padding: 50px 0 22px;
}
.ttm-row.services-details-section {
	padding: 100px 0 40px;
}
.ttm-row.services-section-icon {
	padding: 85px 0 105px;
	@media (max-width: 991px){
		padding: 60px 0 70px;
	}
}
.ttm-row.progress-section {
	padding: 100px 0 53px;
	@media (max-width: 991px){
		padding: 60px 0 15px;
	}
}
.ttm-row.contact-us-section {
	padding: 0 0 55px;
	margin-top: -30px;
}
.ttm-row.portfolio-section {
	padding: 95px 0 0px;
	@media only screen and (max-width: 991px) {
		padding: 60px 0 0px;
	}
}


/* ===============================================
    2.Row-Equal-Height
------------------------*/
.row-equal-height {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	>[class*='col-'] {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

/* ===============================================
    3.Bg-Layer 
------------------------*/
.ttm-bg {
	position: relative;
}
.ttm-col-bgimage-yes {
	z-index: 1;
}
.bg-layer-equal-height {
	.ttm-col-bgimage-yes {
		height: 100%;
	}
	.ttm-col-bgcolor-yes {
		height: 100%;
	}
}
.ttm-bg-layer {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}
.ttm-col-wrapper-bg-layer-inner {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}
.ttm-left-span {
	>.ttm-col-wrapper-bg-layer {
		width: auto;
		margin-left: -500px;
		right: 0;
	}
}
.ttm-right-span {
	>.ttm-col-wrapper-bg-layer {
		width: auto;
		margin-right: -500px;
		right: 0;
	}
}
.layer-content {
	position: relative;
	z-index: 1;
}
.bg-layer {
	position: relative;
	>.container {
		>.row {
			margin: 0;
		}
		padding: 0;
	}
	>.container-fluid {
		padding: 0;
	}
}
.ttm-bg.ttm-bgimage-yes {
	>.ttm-bg-layer {
		opacity: .88;
	}
}
.ttm-bg.ttm-col-bgimage-yes {
	>.ttm-bg-layer {
		>.ttm-col-wrapper-bg-layer-inner {
			opacity: .88;
		}
	}
}
.ttm-bgcolor-white.ttm-bg.ttm-bgimage-yes {
	>.ttm-bg-layer {
		opacity: .94;
	}
}
.ttm-bgcolor-white.ttm-bg.ttm-col-bgimage-yes {
	>.ttm-bg-layer {
		>.ttm-col-wrapper-bg-layer-inner {
			opacity: .94;
		}
	}
}
.ttm-equal-height-image {
	display: none;
}

@media (max-width: 1199px) {
	[class*='col-xl'] .ttm_single_image-wrapper {
    text-align: center;
	}
}

/* ===============================================
    6.Section-Title  
------------------------*/
.section-title {
	position: relative;
	margin-bottom: 18px;
	margin-top: -7px;
	h3 {
		display: inline-block;
		text-transform: capitalize;
		font-family: "Caveat", Arial, Helvetica, sans-serif;
		font-weight: 700;
		font-size: 24px;
		line-height: 26px;
		color: #097ae9;
		margin-bottom: 15px;
		position: relative;
	}
	h2.title {
		text-transform: capitalize;
		font-weight: 600;
    	font-size: 68px;
    	line-height: 78px;
		margin-bottom: 15px;
		@media (max-width: 991px) {
			font-size: 40px;
    		line-height: 50px;
		}
	}
	.title-desc {
		p {
			font-size: 16px;
			line-height: 28px;
			padding-bottom: 10px;
			margin: 0;
		}
	}
	.title-header {
		padding-bottom: 11px;
	}
}
span.webkit-content {
	-webkit-text-stroke-width: 2px;
    -webkit-text-fill-color: transparent;
}
.section-title.title-style-center_text {
	text-align: center;
	.title-desc {
		p {
			margin-bottom: 15px;
			width: 55%;
			margin: 0 auto;
		}
	}
}
.bg-theme-DarkColor {
	.section-title {
		h3 {
			color: $theme-SkinColor;
		}
		h2.title {
			color: $theme-WhiteColor;
		}
	}
}
.bg-theme-SkinColor {
	.section-title {
		h3 {
			color: $theme-WhiteColor;
		}
		h2.title {
			color: $theme-WhiteColor;
		}
	}
}
/* style1 */
.section-title.style1 {
	text-align: right;
	padding-right: 20px;
	padding-top: 50px;
	@media (max-width: 767px) {
		text-align: left;
		padding-top: 0;
	}
	.title-header {
		h2 {
		font-size: 36px;
    	line-height: 42px;
		
		}
	}
}
@media (max-width: 991px){
	.section-title.style1 {
		text-align: left;
	}
}
/* style2 */
.section-title.style2 {
	.title-header {
		h2 {
		font-size: 24px;
		line-height: 34px;
		margin-bottom: 7px;;
		color: $white;
		}
	}
	.title-desc {
		color: rgba(255,255,255,.85);
		padding-right: 30px;
	}
}

/* style3 */
.section-title.style3 {
	h3 {
	font-weight: 600;
	font-size: 18px;
	line-height: 28px;
	color: $black;
	padding-left: 15px;
	font-family: $headingfont;
	}
}

/* style4 */
.section-title.style4 {
	text-align: center;
	padding-top: 150px;
	margin-top: 0;
	padding-bottom: 110px;
	@media (max-width: 1200px){
		padding-top: 45px;
		padding-bottom: 45px;
	}
	h3 {
		font-size: 118px;
		line-height: 128px;
		-webkit-text-stroke-width: 2px;
    	-webkit-text-fill-color: transparent;
		font-family: "Syne", Tahoma, Geneva, sans-serif;
		@media (max-width: 1400px){
			font-size: 80px;
			line-height: 90px;
		}
	}
	h2.title {
		font-size: 52px;
		font-weight: 600;
		line-height: 60px;
		@media (max-width: 1400px){
			font-size: 40px;
			line-height: 50px;
		}
	}
}

/* style5 */
.section-title.style5 {
    padding-left: 25px;
	.title-header h2 {
		font-size: 150px;
		font-weight: 600;
		line-height: 150px;
		color: $theme-SkinColor;
		@media only screen and (max-width: 991px) {
			font-size: 100px;
			line-height: 110px;
		}
	}
	.title-desc {
		position: absolute;
		color: #FFFFFF;
		width: 12%;
		max-width: 12%;
		top: 20px;
		right: 40px;
	}
	.title-desc p {
		transform: rotate(90deg);
		font-size: 16px;
		font-weight: 500;
	}
}

.separator .sep-line {
    height: 1px;
    border-top: 1px solid #c4c4c4;
    display: block;
    position: relative;
    width: 100%;
}
.separator .sep-line-footer {
	height: 1px;
    border-top: 1px solid #FFFFFF30;
    display: block;
    position: relative;
    width: 100%;
}
.ttm-single-img-1 {
	-webkit-animation: shake 20s linear infinite;
    -moz-animation: shake 20s linear infinite;
    -o-animation: shake 20s linear infinite;
    animation: shake 20s linear infinite;
}
.text-bottom {
	u:hover {
		color: $theme-DarkColor;
		a:hover {
			color: $theme-DarkColor;
		}
	}
}


/* ===============================================
    4.Row-Bg-Image  
------------------------*/
.bg-img1{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
}
.bg-img2{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
}
.bg-img3 {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
}


/* ===============================================
    5.Col-Bg-Image  
------------------------*/
.ttm-equal-height-image{ display: none; }
.ttm-bg{ 
    position: relative;
    &.ttm-bgcolor-grey:not(.ttm-col-bgcolor-yes) .ttm-bg-layer {
        opacity: 0.7;
    }
    &.ttm-bgcolor-darkgrey .ttm-bg-layer {
        opacity: 0.8;
    }
}
.ttm-col-bgimage-yes, .bg-layer-equal-height .ttm-col-bgcolor-yes{ height: 100%; z-index: 9;}
.ttm-bg-layer , .ttm-titlebar-wrapper .ttm-titlebar-wrapper-bg-layer,
.ttm-bg-layer .ttm-bg-layer-inner {
    position: absolute;
    height: 100%;
    width: 100%; 
    top: 0;
    left: 0;
}
.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
	background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
}
.ttm-bg-layer, .ttm-bg-layer .ttm-bg-layer-inner {
    background-color: inherit;
    opacity: 1;
}


/* ===============================================
    7.sep_holder
------------------------*/
.ttm-horizontal_sep {
	border-top: 1px solid rgba(0, 0, 0, 0.04);
	display: block;
	position: relative;
}
.ttm-bgcolor-white {
	.ttm-horizontal_sep {
		border-top-color: #f1f1f1;
	}
}
.border {
	border-color: #c4c4c4 !important;
}
.border-top {
	border-color: #c4c4c4 !important;
}
.border-left {
	border-color: #c4c4c4 !important;
}
.border-bottom {
	border-color: #c4c4c4 !important;
}
.border-right {
	border-right: 1px solid #c4c4c4 !important;
}
.ttm-vertical_sep {
	>[class*='col-'] {
		&:not(:last-child) {
			&:before {
				position: absolute;
				content: "";
				height: 100%;
				top: 0;
				right: 15px;
				width: 1px;
				background-color: rgba(0, 0, 0, 0.06);
			}
		}
	}
}
.row.no-gutters.ttm-vertical_sep {
	>[class*='col-'] {
		&:not(:last-child) {
			&:before {
				right: 0;
			}
		}
	}
}
.ttm-bgcolor-skincolor {
	.border {
		border-color: rgba(255,255,255,0.06) !important;
	}
	.border-top {
		border-color: rgba(255,255,255,0.06) !important;
	}
	.border-left {
		border-color: rgba(255,255,255,0.06) !important;
	}
	.border-bottom {
		border-color: rgba(255,255,255,0.06) !important;
	}
	.border-right {
		border-color: rgba(255,255,255,0.06) !important;
	}
	.ttm-vertical_sep {
		>[class*='col-'] {
			&:not(:last-child) {
				&:before {
					background-color: rgba(255,255,255,0.15);
				}
			}
		}
	}
}
.ttm-bgcolor-darkgrey {
	.ttm-horizontal_sep {
		border-top-color: rgba(255,255,255,0.08);
	}
	.border {
		border-color: rgba(255,255,255,0.06) !important;
	}
	.border-top {
		border-color: rgba(255,255,255,0.06) !important;
	}
	.border-left {
		border-color: rgba(255,255,255,0.06) !important;
	}
	.border-bottom {
		border-color: rgba(255,255,255,0.06) !important;
	}
	.border-right {
		border-color: rgba(255,255,255,0.06) !important;
	}
	.ttm-vertical_sep {
		>[class*='col-'] {
			&:not(:last-child) {
				&:before {
					background-color: rgba(255,255,255,0.15);
				}
			}
		}
	}
}


/* ===============================================
    8.Buttons
------------------------*/
.ttm-btn {
	display: inline-block;
	vertical-align: middle;
	font-size: 15px;
	font-family: $headingfont;
	line-height: normal;
	padding: 11px 32px 11px 31px;
	border-width: 1px;
	border-style: solid;
	border-color: #eaeff5;
	position: relative;
	white-space: nowrap;
	text-transform: capitalize;
	font-weight: bold;
	overflow: hidden;
	z-index: 1;
	transition: all 0.5s ease 0s;
	&:not(.btn-inline) {
		&::before {
			content: '';
			position: absolute;
			background-color: #343434;
			height: 0%;
			width: 100%;
			bottom: 0;
			top: auto;
			left: 0;
			-webkit-transition: .3s all ease-in-out;
			-o-transition: .3s all ease-in-out;
			transition: .3s all ease-in-out;
			z-index: -1;
		}
		&:hover {
			&::before {
				bottom: auto;
				top: 0;
				height: 100%;
			}
		}
	}
	i {
		display: inline-block;
		vertical-align: middle;
		text-align: inherit;
		margin-left: 10px;
		transition: .4s;
	}
}
.ttm-btn {
	&:hover.btn-inline.ttm-btn-color-skincolor {
		background-color: transparent;
		color: #fff;
	}
}
/** btn-with-icon **/
.ttm-btn.ttm-icon-btn-left {
	padding-left: 22px;
	transition: unset;
	i {
		text-align: left;
		margin-right: 10px;
		margin-left: 0;
	}
}
.ttm-btn.ttm-icon-btn-right {
	padding-right: 22px;
	transition: unset;
	i {
		right: 2px;
		text-align: right;
		margin-left: 10px;
		margin-right: 0;
	}
}
/** btn-size-xs **/
.ttm-btn.ttm-btn-size-xs {
	font-size: 11px;
	padding: 8px 20px;
	i {
		font-size: 10px;
		line-height: 11px;
	}
}
.ttm-btn.ttm-icon-btn-right.ttm-btn-size-xs {
	i {
		text-align: right;
		margin-left: 6px;
		margin-right: 0;
	}
}
.ttm-btn.ttm-icon-btn-left.ttm-btn-size-xs {
	i {
		text-align: left;
		margin-right: 6px;
		margin-left: 0;
	}
}
/** btn-size-sm **/
.ttm-btn.ttm-btn-size-sm {
	font-size: 13px;
	padding: 11px 24px 9px 24px;
	i {
		font-size: 13px;
		line-height: 14px;
	}
}
/** btn-size-md **/
.ttm-btn.ttm-btn-size-md {
	font-size: 14px;
	padding: 15px 30px;
	i {
		font-size: 15px;
		line-height: 15px;
	}
}
/** btn-size-lg **/
.ttm-btn.ttm-btn-size-lg {
	font-size: 16px;
	line-height: 16px;
	padding: 16px 30px;
	i {
		font-size: 16px;
		line-height: 16px;
	}
}
/** btn-shape **/
.ttm-btn.ttm-btn-shape-round {
	border-radius: 2em;
}
.ttm-btn.ttm-btn-shape-rounded {
	border-radius: 8px;
}
.ttm-btn.ttm-btn-shape-square {
	border-radius: 0;
}
/** btn-style-border **/
.ttm-btn.ttm-btn-style-border {
	background-color: transparent;
	border: 2px solid;
	border-radius: 8px;
}
.ttm-btn.ttm-btn-style-border.text-theme-WhiteColor {
	color: #fff;
	border-color: #fff;
	background-color: transparent;
}
/** btn-color **/
a.ttm-btn.ttm-btn-color-skincolor {
	color: $theme-SkinColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-skincolor {
	background-color: $theme-SkinColor;
	border-color: $theme-SkinColor;
	color: $white;
}
a.ttm-btn.ttm-btn-color-dark {
	color: $theme-DarkColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-dark {
	background-color: $theme-DarkColor;
	border-color: $theme-DarkColor;
	color: $white;
}
a.ttm-btn.ttm-btn-color-white {
	color: $theme-WhiteColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-white {
	background-color: $theme-WhiteColor;
	color: $theme-DarkColor;
}
a.ttm-btn.ttm-btn-color-grey {
	color: $theme-GreyColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-grey {
	background-color: $theme-GreyColor;
	border-color: $theme-GreyColor;
	color: $theme-SkinColor;
}
/** btn-hover **/
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-skincolor:hover::before {
	background-color: $theme-DarkColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-skincolor:hover {
	border-color: $theme-DarkColor;
	color: $white;
}
.ttm-btn.ttm-btn-style-border.ttm-btn-color-skincolor:hover {
	border-color: $theme-SkinColor;
	color: $white;
}
.ttm-btn.ttm-btn-style-border.ttm-btn-color-skincolor:hover::before{
	background-color: $theme-SkinColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-dark:hover::before {
	background-color: $theme-SkinColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-dark:hover {
	border-color: $theme-SkinColor;
	color: $white;
}
.ttm-btn.ttm-btn-style-border.ttm-btn-color-dark:hover {
	border-color: $theme-DarkColor;
	color: $white;
}
.ttm-btn.ttm-btn-style-border.ttm-btn-color-dark:hover::before{
	background-color: $theme-DarkColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-white:hover::before {
	background-color: $theme-DarkColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-white:hover {
	border-color: $theme-DarkColor;
	color: $white;
}
.ttm-btn.ttm-btn-style-border.ttm-btn-color-white:hover {
	border-color: $theme-WhiteColor;
	color: $theme-DarkColor;
}
.ttm-btn.ttm-btn-style-border.ttm-btn-color-white:hover::before{
	background-color: $theme-WhiteColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-grey:hover::before {
	background-color: $theme-DarkColor;
}
.ttm-btn.ttm-btn-style-fill.ttm-btn-color-grey:hover {
	border-color: $theme-DarkColor;
	color: $white;
}
.ttm-btn.ttm-btn-style-border.ttm-btn-color-grey:hover {
	border-color: $theme-GreyColor;
	color: $theme-SkinColor;
}
.ttm-btn.ttm-btn-style-border.ttm-btn-color-grey:hover::before{
	background-color: $theme-GreyColor;
}

.ttm-btn.ttm-btn-style-fill.ttm-btn-color-skincolor:disabled{
	background-color: #9d9d9d;
	border-color: #9d9d9d;
}

/** btn-inline **/
.ttm-btn.btn-inline {
	text-transform: capitalize;
	padding: 0;
	border: 0;
	background-color: transparent;
	i {
		display: inline-block;
		vertical-align: middle;
		transition: 0s;
	}
	i.fa-minus {
		&:before {
			height: 1px;
			display: block;
		}
	}
	&:hover {
		opacity: .9;
	}
}
.ttm-btn.btn-inline.ttm-icon-btn-right {
	i {
		margin-left: 7px;
		transition: ease-in-out .3s;
	}
	&:hover {
		i {
			margin-left: 11px !important;
		}
	}
}
.ttm-btn.btn-inline.ttm-icon-btn-left {
	i {
		margin-right: 7px;
		transition: ease-in-out .3s;
	}
	&:hover {
		i {
			margin-right: 11px !important;
		}
	}
}
/* play-btn / play-icon */
.ttm-play-icon {
	color: #fff;
	display: block;
	position: relative;
	.ttm-icon.ttm-icon_element-size-md {
		height: 95px;
		width: 95px;
		line-height: 95px;
		margin: 0;
	}
	.ttm-play-icon-animation {
		display: inline-block;
		position: relative;
		&:after {
			content: '';
			border: 1px solid;
			border-color: inherit;
			width: 150%;
			height: 150%;
			-webkit-border-radius: 100%;
			border-radius: 100%;
			position: absolute;
			left: -25%;
			top: -25%;
			opacity: 1;
			-webkit-animation: 1s videoplay-anim linear infinite;
			animation: 1s videoplay-anim linear infinite;
			-webkit-animation: videoplay-anim 1.05s infinite;
			-moz-animation: videoplay-anim 1.05s infinite;
			-ms-animation: videoplay-anim 1.05s infinite;
			-o-animation: videoplay-anim 1.05s infinite;
			animation: videoplay-anim 1.05s infinite;
			border-color: #bfbfbf;
		}
		&:before {
			content: '';
			border: 1px solid;
			border-color: inherit;
			width: 150%;
			height: 150%;
			-webkit-border-radius: 100%;
			border-radius: 100%;
			position: absolute;
			left: -25%;
			top: -25%;
			opacity: 1;
			-webkit-animation: 1s videoplay-anim linear infinite;
			animation: 1s videoplay-anim linear infinite;
			-webkit-animation: videoplay-anim 1.05s infinite;
			-moz-animation: videoplay-anim 1.05s infinite;
			-ms-animation: videoplay-anim 1.05s infinite;
			-o-animation: videoplay-anim 1.05s infinite;
			animation: videoplay-anim 1.05s infinite;
			border-color: #e4e4e4;
			-webkit-animation-delay: .5s;
			animation-delay: .5s;
		}
	}
}
.ttm-play-icon-btn {
	.ttm-icon.ttm-icon_element-size-sm {
		display: block;
		height: 50px;
		width: 50px;
		line-height: 50px;
		margin: 0;
		z-index: 2;
		i.fa-play {
			padding-left: 5px;
			font-size: 20px;
			display: inline-block;
			vertical-align: middle;
			line-height: 0;
		}
	}
	.ttm-icon.ttm-icon_element-size-md {
		display: block;
		height: 67px;
		width: 67px;
		line-height: 67px;
		margin: 0;
		z-index: 2;
		i.fa-play {
			font-size: 28px;
		}
	}
	.ttm-play-icon-animation {
		position: relative;
		display: inline-block;
		.ttm-icon {
			margin-bottom: 0;
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			border-radius: 50%;
			z-index: 1;
			width: calc(100% * 1.3 );
			height: calc(100% * 1.3 );
			opacity: .3;
			background-color: #fff;
		}
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			border-radius: 50%;
			width: calc(100% * 1.6 );
			height: calc(100% * 1.6 );
			opacity: .5;
			background-color: #fff;
		}
	}
	&:hover {
		.ttm-play-icon-animation {
			&:after {
				-webkit-animation: sep-anim 1.05s infinite;
				-moz-animation: sep-anim 1.05s infinite;
				-ms-animation: sep-anim 1.05s infinite;
				-o-animation: sep-anim 1.05s infinite;
				animation: sep-anim 1.05s infinite;
			}
			&:before {
				-webkit-animation: sep-anim 1.05s infinite;
				-moz-animation: sep-anim 1.05s infinite;
				-ms-animation: sep-anim 1.05s infinite;
				-o-animation: sep-anim 1.05s infinite;
				animation: sep-anim 1.05s infinite;
			}
		}
	}
}
i.fa-play {
	padding-left: 5px;
}
.ttm-play-icon-btn {
	.ttm-play-icon-animation {
		&:after {
			opacity: .1;
		}
		&:before {
			opacity: .3;
		}
	}
}


/* ===============================================
    9.Icons
------------------------*/
.ttm-icon {
	margin-bottom: 25px;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	border: 1px solid transparent;
	position: relative;
	transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	box-sizing: content-box;
	position: relative;
	i {
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
		-o-transform: translate(-50%,-50%);
		transform: translate(-50%,-50%);
	}
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-color-skincolor {
	background-color: $theme-SkinColor;
	color: $theme-WhiteColor;
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-color-darkgrey {
	background-color: $theme-DarkColor;
	color: $theme-WhiteColor;
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-color-grey {
	background-color: $theme-GreyColor;
	color: $theme-SkinColor;
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-color-white {
	background-color: $theme-WhiteColor;
	color: $theme-SkinColor;
}

/** icon-size-xs **/
.ttm-icon.ttm-icon_element-size-xs {
	height: 40px;
	width: 40px;
	line-height: 40px;
	i {
		font-size: 18px;
	}
}
/** icon-size-sm **/
.ttm-icon.ttm-icon_element-size-sm {
	height: 50px;
	width: 50px;
	line-height: 50px;
	i {
		font-size: 30px;
	}
}
/** icon-size-md **/
.ttm-icon.ttm-icon_element-size-md {
	height: 67px;
	width: 67px;
	line-height: 67px;
	i {
		font-size: 35px;
	}
}
/** icon-size-lg **/
.ttm-icon.ttm-icon_element-size-lg {
	height: 78px;
	width: 78px;
	line-height: 78px;
	i {
		font-size: 50px;
	}
}
/** icon-size-xl **/
.ttm-icon.ttm-icon_element-size-xl {
	height: 88px;
	width: 80px;
	line-height: 88px;
	i {
		font-size: 60px;
	}
}
.ttm-icon.ttm-icon_element-onlytxt {
	height: auto;
	width: auto;
	line-height: 1;
	i {
		position: relative;
		top: 0;
		left: 0;
		-webkit-transform: unset;
		-ms-transform: unset;
		-o-transform: unset;
		transform: unset;
		-webkit-transform: translate(0,0);
		-ms-transform: translate(0,0);
		-o-transform: translate(0,0);
		transform: translate(0,0);
		transform: translate(0,0);
	}
}
/** icon-shape **/
.ttm-icon.ttm-icon_element-style-round {
	border-radius: 5px;
}
.ttm-icon.ttm-icon_element-style-rounded {
	border-radius: 50%;
}
.ttm-icon.ttm-icon_element-style-square {
	border-radius: 0;
}
/* ttm-list-style-icon */
.ttm-list {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 16px;
}
.ttm-list.ttm-list-style-icon.font-weight-normal {
	li {
		i {
			font-weight: normal;
			padding-bottom: 2px;
		}
	}
	.ttm-list-li-content {
		font-weight: normal;
		padding-bottom: 2px;
	}
}
.ttm-list.ttm-list-style-icon {
	li {
		position: relative;
		padding-bottom: 8px;
		&:last-child {
			padding-bottom: 0;
		}
		i {
			position: absolute;
			left: auto;
			top: 4px;
			font-weight: normal;
			font-size: 16px;
		}
		i.fa-minus {
			&:before {
				position: absolute;
				content: "";
				top: 6px;
				left: 0;
				height: 2px;
				width: 7px;
				background-color: currentcolor;
			}
		}
	}
	.ttm-list-li-content {
		display: inline-block;
		padding-left: 25px;
	}
}
.ttm-list-icon-color-skincolor i {
	color: $theme-SkinColor;
}
.ttm-list-icon-color-darkgrey i {
	color: $theme-DarkColor;
}
.ttm-list.ttm-list-textsize-large {
	li {
		font-size: 20px;
		padding-bottom: 11px;
		.ttm-list-li-content {
			padding-left: 30px;
		}
	}
}
.ttm-list.ttm-list-textsize-small {
	li {
		font-size: 12px;
		.ttm-list-li-content {
			padding-left: 16px;
		}
	}
}

/* list-style-1 */
.ttm-list.style1 {
	display: flex;
	@media (max-width: 767px) {
		display: block;
	}
	li {
		width: auto;
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: inherit;
		margin-bottom: 10px;
		text-align: left;
		margin-right: calc(47px/2);
		margin-left: calc(25px/2);
	}
	@media (max-width: 1200px) {
		margin-bottom: 40px;
	}
	i {
		background-color: $theme-GreyColor;
		min-width: 40px;
		min-height: 40px;
		line-height: 40px;
		height: 40px;
		width: 40px;
		font-size: 14px;
		text-align: center;
		border-radius: 50%;
		display: block;
		color: $black;
	}
	span.ttm-list-li-content {
		font-size: 18px;
		line-height: 30px;
		font-weight: 700;
		padding-left: 12px;
		color: $black;
	}
	li:hover i {
		background-color: $theme-SkinColor;
		color: $theme-WhiteColor;
		transition: .5s
	}
}

/* list-style-2 */
.ttm-list.style2 {
	display: flex;
	margin-top: 45px;
	li {
		padding-right: 70px;
		i {
			font-size: 24px;
			color: #097ae9;
			padding-right: 15px;
			top: 7px;
			position: relative;
		}
	}
	span.ttm-list-li-content {
		font-size: 20px;
    	line-height: 30px;
		color: $theme-DarkColor;
		font-weight: 600;
	}
}
@media (max-width: 767px){
	.ttm-list.style2 {
		display: block;
	}
}
@media (max-width: 991px){
	.ttm-list.style2 {
		margin-bottom: 30px;
	}
}

/* list-style-3 */
.ttm-list.style3 {
	margin-top: 0px;
	li {
		i {
			font-size: 18px;
			color: $theme-WhiteColor;
			padding-right: 15px;
			top: 7px;
			position: relative;
		}
	}
	span.ttm-list-li-content {
		font-size: 15px;
    	line-height: 25px;
		color: $theme-WhiteColor;
		font-weight: 600;
	}
}


/* ttm-video-icon */
.ttm-video-icon {
	.ttm-icon.ttm-icon_element-size-md {
		height: 98px;
		width: 98px;
		line-height: 98px;
		i {
			font-size: 40px;
		}
	}
}
.ttm-right-video-icon {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
}
.ttm-left-video-icon {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
}
.ttm-center-video-icon {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
}

/*marque-box*/
.ttm-marquebox-wrapper {
    width: 65%;
    animation: 15s linear infinite ttm-marque-text;
    will-change: transform;
    white-space: nowrap;
}
.marquebox-list {
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
}
.ttm-marquebox-wrapper .marquebox-list .marquebox-item {
    position: relative;
    display: inline-block;
    margin-left: 65px;
    margin-right: 65px;
    font-size: 68px;
    line-height: 78px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 700;
	color: transparent;
	cursor: pointer;
    -webkit-text-stroke: 2px #000;
	@media (max-width: 991px){
		font-size: 40px;
		line-height: 50px;
	}
}
.ttm-marquebox-wrapper .marquebox-list .marquebox-item:before {
    content: '';
    font-size: 68px;
    line-height: 78px;
    height: 7px;
    width: 60px;
    position: absolute;
    background-color: transparent;
    bottom: auto;
    top: 40px;
    left: auto;
    right: -100px;
    transform: rotate(-60deg);
    border: 1px solid #000000;
	@media (max-width: 991px) {
		content: '';
		font-size: 38px;
		line-height: 48px;
		height: 8px;
		width: 40px;
		position: absolute;
		background-color: transparent;
		bottom: auto;
		top: 25px;
		left: auto;
		right: -85px;
		transform: rotate(-60deg);
		border: 1px solid #fff;
	}
}
.ttm-marquebox-wrapper .marquebox-list .marquebox-item:nth-child(2n) {
    color: transparent;
    -webkit-text-stroke: 2px #000;
}
.ttm-marquebox-wrapper .marquebox-list .marquebox-item:hover {
    -webkit-text-stroke: 2px $theme-SkinColor;
    color: $theme-SkinColor;
}
@keyframes ttm-marque-text {
    0% {
        transform: translate(0,0)
    }

    100% {
        transform: translate(-100%,0)
    }
}

// marque style-1
.ttm-marquebox-wrapper.style1 {
    width: 65%;
    animation: 15s linear infinite ttm-marque-text;
    will-change: transform;
    white-space: nowrap;
}
.marquebox-list {
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
}
.ttm-marquebox-wrapper.style1 .marquebox-list .marquebox-item {
    position: relative;
    display: inline-block;
    margin-left: 65px;
    margin-right: 65px;
    font-size: 68px;
    line-height: 78px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 700;
	cursor: pointer;
    color: transparent;
    -webkit-text-stroke: 2px #fff;
	@media (max-width: 991px){
		font-size: 40px;
		line-height: 50px;
	}
}
.ttm-marquebox-wrapper.style1 .marquebox-list .marquebox-item:before {
    content: '';
    font-size: 68px;
    line-height: 78px;
    height: 7px;
    width: 60px;
    position: absolute;
    background-color: transparent;
    bottom: auto;
    top: 40px;
    left: auto;
    right: -100px;
    transform: rotate(-60deg);
    border: 1px solid #fff;
	@media (max-width: 991px) {
		content: '';
		font-size: 38px;
		line-height: 48px;
		height: 8px;
		width: 40px;
		position: absolute;
		background-color: transparent;
		bottom: auto;
		top: 25px;
		left: auto;
		right: -85px;
		transform: rotate(-60deg);
		border: 1px solid #fff;
	}
}
.ttm-marquebox-wrapper.style1 .marquebox-list .marquebox-item:nth-child(2n) {
    color: transparent;
    -webkit-text-stroke: 2px #fff;
}
.ttm-marquebox-wrapper.style1 .marquebox-list .marquebox-item:hover {
    -webkit-text-stroke: 2px $theme-SkinColor;
    color: $theme-SkinColor;
}
@keyframes ttm-marque-text {
    0% {
        transform: translate(0,0)
    }

    100% {
        transform: translate(-100%,0)
    }
}

// marque style-2

.ttm-marquebox-wrapper.style2 {
    width: 100%;
    animation: ttm-marque-text-1 25s linear infinite;
    will-change: transform;
    white-space: nowrap;
}
.marquebox-list {
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
}
.ttm-marquebox-wrapper.style2 .marquebox-list .marquebox-item {
    position: relative;
    display: inline-block;
    margin-left: 65px;
    margin-right: 65px;
    font-size: 68px;
    line-height: 78px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 700;
	cursor: pointer;
    color: transparent;
    -webkit-text-stroke: 2px #fff;
	@media (max-width: 991px){
		font-size: 40px;
		line-height: 50px;
	}
}
.ttm-marquebox-wrapper.style2 .marquebox-list .marquebox-item:before {
    content: '';
    font-size: 68px;
    line-height: 78px;
    height: 7px;
    width: 60px;
    position: absolute;
    background-color: transparent;
    bottom: auto;
    top: 40px;
    left: auto;
    right: -100px;
    transform: rotate(-60deg);
    border: 1px solid #fff;
	@media (max-width: 991px) {
		content: '';
		font-size: 38px;
		line-height: 48px;
		height: 8px;
		width: 40px;
		position: absolute;
		background-color: transparent;
		bottom: auto;
		top: 25px;
		left: auto;
		right: -85px;
		transform: rotate(-60deg);
		border: 1px solid #fff;
	}
}
.ttm-marquebox-wrapper.style2 .marquebox-list .marquebox-item:nth-child(2n) {
    color: transparent;
    -webkit-text-stroke: 2px #fff;
}
.ttm-marquebox-wrapper.style2 .marquebox-list .marquebox-item:hover {
    -webkit-text-stroke: 2px $theme-SkinColor;
    color: $theme-SkinColor;
}
@keyframes ttm-marque-text-1 {
	100% {
        transform: translate(0,0)
    }
    0% {
		transform: translate(-100%,0)
    }
}

// marque style-3
.ttm-marquebox-wrapper.style3 {
    width: 65%;
    animation: 15s linear infinite ttm-marque-text;
    will-change: transform;
    white-space: nowrap;
}
.marquebox-list {
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
}
.ttm-marquebox-wrapper.style3 .marquebox-list .marquebox-item {
    position: relative;
    display: inline-block;
    margin-left: 65px;
    margin-right: 65px;
    font-size: 68px;
    line-height: 78px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 700;
	color: transparent;
	cursor: pointer;
    -webkit-text-stroke: 2px #fff;
	@media (max-width: 991px){
		font-size: 40px;
		line-height: 50px;
	}
}
.ttm-marquebox-wrapper.style3 .marquebox-list .marquebox-item:before {
    content: '';
    font-size: 68px;
    line-height: 78px;
    height: 7px;
    width: 60px;
    position: absolute;
    background-color: transparent;
    bottom: auto;
    top: 40px;
    left: auto;
    right: -100px;
    transform: rotate(-60deg);
    border: 1px solid #fff;
	@media (max-width: 991px) {
		content: '';
		font-size: 38px;
		line-height: 48px;
		height: 8px;
		width: 40px;
		position: absolute;
		background-color: transparent;
		bottom: auto;
		top: 25px;
		left: auto;
		right: -85px;
		transform: rotate(-60deg);
		border: 1px solid #fff;
	}
}
.ttm-marquebox-wrapper.style3 .marquebox-list .marquebox-item:nth-child(2n) {
    color: transparent;
    -webkit-text-stroke: 2px #fff;
}
.ttm-marquebox-wrapper.style3 .marquebox-list .marquebox-item:hover {
    -webkit-text-stroke: 2px $theme-WhiteColor;
    color: $theme-WhiteColor;
}
@keyframes ttm-marque-text {
    0% {
        transform: translate(0,0)
    }

    100% {
        transform: translate(-100%,0)
    }
}

// marque style-4
.ttm-marquebox-wrapper.style4 {
    width: 65%;
    animation: 15s linear infinite ttm-marque-text;
    will-change: transform;
    white-space: nowrap;
}
.marquebox-list {
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
}
.ttm-marquebox-wrapper.style4 .marquebox-list .marquebox-item {
    position: relative;
    display: inline-block;
    margin-left: 65px;
    margin-right: 65px;
    font-size: 68px;
    line-height: 78px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 700;
	color: transparent;
	cursor: pointer;
    -webkit-text-stroke: 2px #000;
	@media (max-width: 991px){
		font-size: 40px;
		line-height: 50px;
	}
}
.ttm-marquebox-wrapper.style4 .marquebox-list .marquebox-item:before {
    content: '';
    font-size: 68px;
    line-height: 78px;
    height: 7px;
    width: 60px;
    position: absolute;
    background-color: transparent;
    bottom: auto;
    top: 40px;
    left: auto;
    right: -100px;
    transform: rotate(-60deg);
    border: 1px solid #000;
	@media (max-width: 991px) {
		content: '';
		font-size: 38px;
		line-height: 48px;
		height: 8px;
		width: 40px;
		position: absolute;
		background-color: transparent;
		bottom: auto;
		top: 25px;
		left: auto;
		right: -85px;
		transform: rotate(-60deg);
		border: 1px solid #fff;
	}
}
.ttm-marquebox-wrapper.style4 .marquebox-list .marquebox-item:nth-child(2n) {
    color: transparent;
    -webkit-text-stroke: 2px #000;
}
.ttm-marquebox-wrapper.style4 .marquebox-list .marquebox-item:hover {
    -webkit-text-stroke: 2px $theme-SkinColor;
    color: $theme-SkinColor;
}
@keyframes ttm-marque-text {
    0% {
        transform: translate(0,0)
    }

    100% {
        transform: translate(-100%,0)
    }
}

/* ===============================================
    10.Fid
------------------------*/
.inside {
	position: relative;
	transition: all .4s;
	padding: 10px 0 15px;
	h4 {
		margin-bottom: 0px;
		font-size: 42px;
		line-height: 52px;
		span {
			margin-bottom: 0px;
			font-size: 42px;
			line-height: 52px;
		}
	}
	h3 {
		margin-bottom: 0;
		font-size: 16px;
		line-height: 26px;
		padding-top: 5px;
		font-weight: normal;
		color: inherit;
		font-family: inherit;
	}
}
.ttm-fid-view-lefticon {
	.ttm-fid-icon-wrapper {
		display: table-cell;
		vertical-align: top;
	}
	.ttm-fid-contents {
		display: table-cell;
		vertical-align: top;
		padding-left: 15px;
		text-align: left;
	}
}
.ttm-fid-view-righticon {
	.ttm-fid-icon-wrapper {
		display: table-cell;
		vertical-align: top;
		padding-left: 15px;
		text-align: left;
	}
	.ttm-fid-contents {
		display: table-cell;
		vertical-align: top;
	}
}
.ttm-fid-view-topicon {
	i {
		margin-bottom: 8px;
	}
}
.ttm-fid-icon-wrapper {
	i {
		font-size: 55px;
		line-height: 1;
		color: $theme-SkinColor;
		display: inline-block;
	}
}
/* fid-1 */
.ttm-fid.inside.style1 {
	padding: 0;
	@media (max-width: 991px) {
		margin-bottom: 20px;
	}
	h4 span {
		display: inline-block;
		font-size: 96px;
		font-weight: 600;
		line-height: 55px;
		color: #097ae9;
	}
	.ttm-fid-contents {
		h3.ttm-fid-title {
			width: auto;
			display: inline-block;
			vertical-align: top;
			font-weight: 600;
			font-size: 15px;
			line-height: initial;
			color: $black;
			writing-mode: vertical-rl;
			transform: rotate(180deg);
		}
	}
	.ttm-fid-inner {
		display: inline-block;
	}
}

/* fid-2 */
.ttm-fid.style2 {
	h4 span {
		position: relative;
		font-size: 104px;
		line-height: 50px;
		margin-bottom: 4px;
		font-weight: 700;
		display: table-cell;
		@media (max-width: 991px){
			font-size: 85px;
		}
		@media (max-width: 767px){
			font-size: 40px;
		}
	}
	h3 {
		font-size: 20px;
		line-height: 25px;
		display: table-cell;
		vertical-align: top;
		font-weight: 700;
		padding-right: 14px;
		color: rgba(255,255,255,1);
	}
	.ttm-fid-contents {
		display: flex;
		@media (max-width: 991px) {
			display: block;
			margin-bottom: 0px;
		}
	}
	
}
	.ttm-fid.style2:after {
		content: '';
		height: 75%;
		width: 1px;
		background-color: #E6E6E6;
		display: block;
		position: absolute;
		right: 30px;
		left: auto;
		top: 10px;
		@media (max-width: 1199px) {
			display: none;
		}
	}
	.fid-section .separator .sep-line {
		border: 1px solid #FFFFFF1A;
	}
	.row > [class*='col-']:last-child >.ttm-fid.style2:after{content: unset;}

/* fid-3 */
.ttm-fid.inside.style3 {
	padding: 50px 20px 55px 10px;
	text-align: center;
	@media (max-width: 991px) {
		padding: 30px 20px 35px 10px;
	}
	h4 span {
		font-size: 68px;
    	line-height: 75px;
		color: $theme-WhiteColor;
	}
	.ttm-fid-contents {
		h3.ttm-fid-title {
			padding-top: 8px;
    		font-size: 20px;
			color: $theme-WhiteColor;
			font-weight: 600;
		}
		
	} 
}
.row > [class*='col-']:not(:last-child) > .ttm-fid.inside.style3:before {
	content: '';
	position: absolute;
	width: 2px;
	height: 27%;
	background-color: #fff;
	right: 0;
	left: auto;
	top: 70px;
	bottom: auto;
	@media (max-width: 991px) {
		content: unset;
	}
}

/* fid-4 */
.ttm-fid-view-righticon.style4 {
	background-color: $theme-GreyColor;
	position: relative;
	border-radius: 8px;
	.ttm-fid-icon-wrapper {
		position: absolute;
		right: 13px;
		top: 20px;
		i {
			font-size: 130px;
			opacity: .1;
			color: $theme-SkinColor;
			display: inline-block;
		}
		
	}
	.ttm-fid-contents {
		padding: 15px 35px;
		h3.ttm-fid-title {
			font-size: 20px;
			font-weight: 700;
		}
		.ttm-fid-inner span {
			font-size: 76px;
			line-height: 86px;
			color: $theme-SkinColor;
		}
		
	}
	.verical-content {
		background-color: $theme-DarkColor;
		padding: 25px 15px;
		writing-mode: vertical-rl;
		transform: rotate(180deg);
		display: table-cell;
		border-radius: 0 8px 8px 0;
		h3 {
			color: $theme-WhiteColor;
			font-size: 15px;
			line-height: 25px;
			margin: 0;
		}
	}
}

/* fid-5 */
.ttm-fid.inside.style5 {
	.ttm-fid-contents {
		h4.ttm-fid-inner {
			display: table-cell;
			span {
			font-size: 68px;
			line-height: 78px;
			font-weight: 700;
			color: $theme-SkinColor;
			vertical-align: middle;
			}
		}
		h3.ttm-fid-title {
			display: table-cell;
			vertical-align: middle;
			font-size: 20px;
			line-height: 30px;
			font-weight: 700;
			color: $black;
			padding: 8px 0 0 30px;
			@media (max-width: 1400px){
				padding: 8px 10px 0 30px;
			}
		}
	}
}
.row > [class*='col-']:not(:last-child) > .ttm-fid.inside.style5:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 80%;
    background-color:#c4c4c4;
    right: 0;
    left: auto;
    top: 20px;
    bottom: auto;
	@media (max-width: 991px){
		content: unset;
	}
}


/* ===============================================
    11.featured-icon-box ( only contents )
------------------------*/

.featured-icon-box {
	position: relative;
	margin: 15px 0;
	.ttm-icon {
		margin-bottom: 0;
	}
}
.featured-title {
	h3 {
		font-size: 22px;
		line-height: 1;
		margin-bottom: 15px;
		font-weight: bold;
		text-transform: capitalize;
	}
}
.featured-icon-box.icon-align-before-content {
	.featured-icon {
		display: table-cell;
		vertical-align: middle;
		i {
			display: inline-block;
			vertical-align: middle;
		}
	}
	.featured-content {
		display: table-cell;
		vertical-align: middle;
		padding-left: 15px;
		.featured-desc {
			p {
				margin-bottom: 0;
			}
		}
	}
	.featured-title {
		h3 {
			margin-bottom: 10px;
		}
	}
}
.featured-icon-box.icon-align-before-title {
	.featured-title {
		display: table-cell;
		vertical-align: middle;
		padding-left: 15px;
		h3 {
			margin-bottom: 0;
		}
	}
	.featured-icon {
		display: table-cell;
		vertical-align: middle;
	}
	.featured-content {
		margin-top: 15px;
	}
}
.featured-icon-box.icon-align-before-content.icon-ver_align-top {
	.featured-icon {
		vertical-align: top;
		padding-top: 4px;
	}
}
.featured-icon-box.icon-align-top-content {
	.featured-content {
		padding-top: 15px;
	}
}
.container-fluid {
	counter-reset: featuredbox-number;
}
.container {
	counter-reset: featuredbox-number;
}
.featuredbox-number {
	i.ttm-num {
		position: relative;
	}
	.ttm-num {
		&:before {
			counter-increment: featuredbox-number;
			content: counter(featuredbox-number, decimal-leading-zero) " ";
		}
	}
}
.featured-icon-box.p-20 {
	&:hover {
		.ttm-icon {
			animation: ttm-bounce 500ms ease-in-out 50ms;
		}
	}
}

// style1
.featured-icon-box.style1 {
	text-align: center;
	background-color: #fff;
	padding: 30px 15px;
	position: relative;
	width: 100%;
	-webkit-box-shadow: 0 0 10px 0 rgba(43,52,59,.09);
	-moz-box-shadow: 0 0 10px 0 rgba(43,52,59,.09);
	box-shadow: 0 0 10px 0 rgba(43,52,59,.09);
	.featured-content {
		padding-top: 30px;
	}
	.ttm-icon {
		i {
			font-size: 40px;
		}
	}
	.featured-title {
		h3 {
			margin-bottom: 10px;
		}
	}
	.featured-desc {
		p {
			margin-bottom: 10px;
		}
	}
}

// style2
.featured-icon-box.style2 {
	padding: 45px 0;
	margin: 0;
	.featured-title {
		h3 {
			font-size: 44px;
			line-height: 50px;
			margin-bottom: 10px;
		}
	}
	.featured-desc {
		p {
			margin: 0;
		}
	}
	.featured-content {
		padding-left: 0;
	}
	.featured-icon {
		.ttm-icon {
			position: absolute;
			opacity: .15;
			top: 15px;
			left: 20px;
			color: #fff;
			display: block;
			font-weight: 400;
			transition: all 1s ease;
			i {
				font-size: 140px;
			}
		}
	}
	&:hover {
		.featured-icon {
			.ttm-icon {
				transform: rotateY(360deg);
			}
		}
	}
}

// style3
.featured-icon-box.style3 {
	margin: 15px 0px;
	.ttm-iconbox-wrapper {
		padding: 45px 50px 40px;
		text-align: left;
		border: 1px solid #c4c4c4;
		border-radius: 8px;
		position: relative;
		background-color: $theme-WhiteColor;
	}
	.ttm-subheading {
		h3 {
		font-size: 15px;
		line-height: 25px;
		color: $theme-SkinColor;
		font-weight: 500;
		}
		h2 {
			font-size: 21px;
			line-height: 30px;
			position: relative;
			margin-bottom: 15px;
		}
	}
	.featured-icon {
		.ttm-icon {
			i {
				color: $theme-DarkColor;
				font-size: 70px;
				margin-top: 30px;
			}
		}
	}
	.ttm-iconbox-wrapper:before {
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		opacity: 0;
		z-index: -1;
		transform: translate(0,0);
		transition: .5s;
		background-color: $theme-GreyColor;
		border-radius: 8px;
		transform: translate(0,0);
		transition: .5s ease-in-out;
		-webkit-transition: all .5s ease-in-out;
		-ms-transition: all .5s ease-in-out;
		-o-transition: all .5s ease-in-out;
		-moz-transition: all .5s ease-in-out;
	} 
}
.featured-icon-box.style3:hover {
	.ttm-iconbox-wrapper:before {
		height: 100%;
		top: 0;
		transform: translate(-20px,20px);
		opacity: 1;
	}
	.featured-icon {
		.ttm-icon {
			i {
				color: $theme-SkinColor;
				transition: all .6s ease;
    			-webkit-transition: all .6s ease;
			}
		}
	}
}

// style4
.featured-icon-box.style4 {
	padding: 50px 28px 50px;
	margin-bottom: 30px;
    text-align: center;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    transition: .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
	margin: 15px 0px 40px;
	.featured-icon {
		margin-bottom: 15px;
		transition: all .6s ease;
		-webkit-transition: all .6s ease;
		-ms-transition: all .6s ease;
		-o-transition: all .6s ease;
		-moz-transition: all .6s ease;
	}
	i {
		color: $theme-DarkColor;
		font-size: 60px;
	}
	.featured-content {
		.featured-title {
			h3 {
				font-size: 20px;
				line-height: 30px;
				font-weight: 600;
				margin-bottom: 10px;
				position: relative;
			}
		}
	}
	.ttm-iconbox {
		position: absolute;
		bottom: -25px;
		right: 130px;
		transition: all .5s ease-in-out;
	}
	.ttm-iconbox a {
		height: 43px;
		width: 43px;
		line-height: 15px;
		text-align: center;
		color: #000000;
		position: relative;
		background-color: $theme-WhiteColor;
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		border-radius: 50%;
		border: 2px solid #000000;
		i {
			color: #010b14;
			font-size: 26px;
		}
	}
}
	.featured-icon-box.style4:hover {
		border-color: #097ae9;
		background-color: #097ae9;
		color: $white;
 		.featured-icon {
		-webkit-transform: translateY(5px) translate3d(0,5px,0);
		-moz-transform: translateY(5px) translate3d(0,5px,0);
		-ms-transform: translateY(5px) translate3d(0,5px,0);
		transform: translateY(5px) translate3d(0,5px,0);
		}
		.featured-icon i {
			color: $white;
		}
		.featured-content {
			.featured-title {
				h3 {
					color: $white;
				}
			}
		}
		.ttm-iconbox a {
			transform: rotate(45deg);
			background-color: $theme-DarkColor;
			border: 2px solid #010b14;
			i {
				color: $theme-WhiteColor;
			}
		}
		
	}

// style-5
.featured-icon-box.style5 {
	.featured-icon { 
		.ttm-icon {
			i {
				color: $theme-SkinColor;
			}
		}
	}
	.featured-content {
		display: table-cell;
		padding-left: 15px;
	}
	.social-icons {
		margin-top: 50px;
		ul {
			li {
			margin-right: 20px;
			font-size: 15px;
			padding-bottom: 0;
			position: relative;
			@media (max-width: 1199px){
				padding-bottom: 10px;
			}
			a {
				width: auto;
				height: auto;
				line-height: initial;
				border-radius: 50% !important;
				border: 0 solid rgba(255, 255, 255, 0.1);
				text-align: center;
				display: block;
				margin: 0;
				font-size: 15px;
				color: $black;
				background: 0 0 !important;
				text-transform: capitalize;
				font-weight: 700;
				}
			}
		} 
		i {
			font-size: 14px;
			border: 1px solid $black;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			display: inline-block;
			text-align: center;
			line-height: 35px;
			margin-right: 17px;
			transition: .5s ease-in-out;
			&:hover {
				border:1px solid $theme-SkinColor;
			}
		}
		li a:hover {
			color: $theme-SkinColor;
		}
	}
}

// style-6
.featured-icon-box.style6 {
	color: $theme-WhiteColor;
	float: right;
	bottom: 110px;
	@media (max-width: 991px){
		bottom: 90px;
	}
	.featured-icon {
		transition: all .5s ease-in-out;
		.ttm-icon {
			a i {
				transition: all .5s ease-in-out;
			}
			
		}
	}
}
.featured-icon-box.style6:hover {
	.featured-icon {
		.ttm-icon {
			a {
				color: $theme-SkinColor;
				border-color: #097ae9;
			}
			
		}
	}
	.featured-icon:hover {
		transform: rotate(45deg);
    	transition: all .5s ease-in-out;
	}
}

// style7
.featured-icon-box.style7 {
	.featured-icon-wrapper {
		margin: 0;
		display: inline-block;
		vertical-align: bottom;
	}
	.featured-icon {
		height: 70px;
		width: 70px;
		line-height: 75px;
		padding: 0;
		.ttm-icon i {
			font-size: 55px;
			margin-top: 7px;
			display: inline-block;
			vertical-align: top;
			transition: .5s;
			color: $theme-DarkColor;
		}
	}
	.featured-content {
		padding-left: 0;;
		vertical-align: bottom;
		.featured-title {
			h3 {
				font-size: 20px;
    			margin: 0 0 8px;
			}
		}
	}
	.featured-desc {
		border-top: 1px solid #c4c4c4;
		padding-top: 25px;
		margin-top: 10px;
		padding-bottom: 20px;
	}
	.ttm-iconbox {
		a:before {
			left: 0;
			font-size: 26px;
			line-height: 40px;
			bottom: -54px;
			color: $theme-DarkColor;
			height: 43px;
			width: 43px;
			text-align: center;
			background-color: $theme-WhiteColor;
			border: 2px solid $black;
			border-radius: 50%;
			content: "\f103";
			position: absolute;
			font-family: 'flaticon';
			font-weight: 500;
			transition: .5s ease-in-out;
		}

	}
}
.featured-icon-box.style7:hover {
	.featured-icon {
		.ttm-icon {
			i {
				color: $theme-SkinColor;
			}
		}
	}
	.ttm-iconbox {
		a:before {
			transform: rotate(45deg);
		}
	}
}

// style8
.featured-icon-box.style8 {
	margin-bottom: 25px;
	.featured-icon {
		display: table-cell;
    	vertical-align: top;
		i {
			font-size: 24px;
			line-height: 50px;
			color: $theme-SkinColor;
		}
	}
	.featured-content {
		.featured-title {
			h3 {
				font-size: 20px;
    			line-height: 30px;
				margin-bottom: 0;
				text-transform: inherit;
			}
		}
	}
}

// style9
.featured-icon-box.style9 {
	padding: 28px 30px 20px;
	border-top: 1px solid $black;
	margin: 0;
	.featured-icon {
		i {
			font-size: 60px;
			color: $theme-DarkColor;
		}
	}
	.featured-content {
		.featured-title {
			h3 {
				font-size: 20px;
				line-height: 28px;
				margin-bottom: 5px;
			}
		}
	}
}
.featured-icon-box.style9:hover {
    background-color: $theme-GreyColor;
}
.row .col-lg-12:last-child .featured-icon-box.style9{border-top: 0px;}

// style10
.featured-icon-box.style10 {
	.featured-icon-wrapper {
		margin: 0;
		display: inline-block;
		vertical-align: bottom;
	}
	.featured-icon {
		height: 70px;
		width: 70px;
		line-height: 75px;
		padding: 0;
		.ttm-icon i {
			font-size: 50px;
			margin-top: 7px;
			display: inline-block;
			vertical-align: top;
			transition: .5s;
			color: $theme-SkinColor;
		}
	}
	.featured-content {
		padding-left: 0;;
		vertical-align: bottom;
		.featured-title {
			h3 {
				font-size: 20px;
				margin-bottom: 20px;
			}
		}
	}
	.featured-desc {
		border-top: 1px solid #c4c4c4;
		padding-top: 25px;
		margin-top: 10px;
		padding-bottom: 20px;
		h3 {
			font-size: 15px;
			font-weight: 700;
			a {
				font-weight: 400;
    			font-size: 16px;
			}
		}
	}
	.ttm-iconbox {
		a:before {
			left: 0;
			font-size: 26px;
			line-height: 40px;
			bottom: -54px;
			color: $theme-DarkColor;
			height: 43px;
			width: 43px;
			text-align: center;
			background-color: $theme-WhiteColor;
			border: 2px solid $black;
			border-radius: 50%;
			content: "\f103";
			position: absolute;
			font-family: 'flaticon';
			font-weight: 500;
			transition: .5s ease-in-out;
		}

	}
}

// style11
.featured-icon-box.style11 {
	border-radius: 8px;
	padding: 40px 45px 25px;
	margin-top: -115px;
	.featured-icon-wrapper {
		margin: 0;
		display: inline-block;
		vertical-align: bottom;
	}
	.featured-icon {
		height: 70px;
		width: 70px;
		line-height: 75px;
		padding: 0;
		.ttm-icon i {
			font-size: 50px;
			margin-top: 7px;
			display: inline-block;
			vertical-align: top;
			transition: .5s;	
		}
	}
	.featured-content {
		padding-left: 0;;
		vertical-align: bottom;
		.featured-title {
			h3 {
				font-size: 20px;
				margin-bottom: 20px;
				color: $white;
			}
		}
	}
	.featured-desc {
		border-top: 1px solid #c4c4c4;
		padding-top: 25px;
		margin-top: 10px;
		padding-bottom: 20px;
		color: rgba(255,255,255,.85);
		h3 {
			font-size: 15px;
			font-weight: 700;
			color: $white;
			a {
				font-weight: 400;
    			font-size: 16px;
				color: $white;
			}
		}
	}
	.ttm-iconbox {
		a:before {
			left: 0;
			font-size: 26px;
			line-height: 40px;
			bottom: -54px;
			color: $white;
			height: 43px;
			width: 43px;
			text-align: center;
			background-color: $theme-WhiteColor;
			border: 2px solid $white;
			border-radius: 50%;
			content: "\f103";
			position: absolute;
			font-family: 'flaticon';
			font-weight: 500;
			transition: .5s ease-in-out;
		}

	}
}
@media (max-width: 991px){
	.featured-icon-box.style11 {
		margin-top: 60px;
	}
}

// style12

.featured-icon-box.style12 {
	padding: 30px 0 38px;
    text-align: center;
	@media (max-width: 991px){
		padding: 10px 0 8px;
	}
	.featured-icon {
		position: relative;
		.ttm-icon {
			i {
				font-size: 70px;
				line-height: 75px;
				color: $theme-DarkColor;
				padding-bottom: 18px;
			}
		}
	}
	.process-num {
		height: 40px;
		width: 40px;
		border-radius: 50%;
		background-color: $theme-SkinColor;
		color: $theme-WhiteColor;
		line-height: 40px;
		text-align: center;
		font-size: 24px;
		font-weight: 600;
		-webkit-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		margin: 0 auto;
	}
	.featured-content {
		padding: 25px 0 0;
		position: relative;
		.featured-title {
			h3 {
				font-weight: 700;
				font-size: 24px;
				line-height: 30px;
			}
		}
	}
	.featured-num-box:hover {
		.process-num {
		background-color: $theme-DarkColor;
		}
	}
}
.featured-icon-box.style12:before {
	content: '';
    position: absolute;
    border: 1px dashed #097ae9;
    width: 100%;
    top: 155px;
    left: 55%;
    bottom: auto;
    right: auto;
}
.row > [class*='col-']:last-child >.featured-icon-box.style12:before{content: unset;}

@media (max-width: 991px){
	.featured-icon-box.style12:before {
		content: unset;
	}
}

/* ===============================================
    12.featured-imagebox ( contents with image)
------------------------*/
.featured-imagebox {
	position: relative;
	overflow: hidden;
	margin: 15px 0;
	.featured-thumbnail {
		position: relative;
		overflow: hidden;
	}
	&:hover{
        .featured-thumbnail img{transform: scale(1.05);}
    }
	.featured-thumbnail img {
		width: 100%;
		height: 100%;
		transform: scale(1);
		transition: all .6s ease-in-out;
		border-radius: 8px;
	}
	.featured-content {
		.featured-title {
			h3 {
				position: relative;
				overflow: hidden;
				font-size: 24px;
				line-height: 30px;
				font-weight: 700;
				margin-bottom: 15px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
		.category {
			font-size: 14px;
			line-height: 15px;
			margin-bottom: 0;
			transition: all .3s;
			display: inline-block;
			a {
				font-size: 14px;
				line-height: 15px;
				margin-bottom: 0;
				transition: all .3s;
				display: inline-block;
			}
		}
		.ttm-btn {
			margin-top: 5px;
		}
	}
}

.portfolio-imagebox {
	position: relative;
	overflow: hidden;
	margin: 15px 0;
	padding-top: 20px;
	.portfolio-thumbnail {
		position: relative;
		width: 400px;
    	height: 400px; 
    	margin: 0 auto;
	}
	&:hover{
        .portfolio-thumbnail img{transform: scale(1.05);}
    }
	.portfolio-thumbnail img {
		width: 100%;
		height: 100%;
		transform: scale(1);
		transition: all .6s ease-in-out;
		border-radius: 8px;
		object-fit: cover;
	}
	.portfolio-content {
		.portfolio-title {
			h3 {
				position: relative;
				overflow: hidden;
				font-size: 24px;
				line-height: 30px;
				font-weight: 700;
				margin-bottom: 15px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
		.category {
			font-size: 14px;
			line-height: 15px;
			margin-bottom: 0;
			transition: all .3s;
			display: inline-block;
			a {
				font-size: 14px;
				line-height: 15px;
				margin-bottom: 0;
				transition: all .3s;
				display: inline-block;
			}
		}
		.ttm-btn {
			margin-top: 5px;
		}
	}
	@media (max-width: 1400px) { /* Estilo para dispositivos con ancho máximo de 1310px (breakpoint sm) */
		.portfolio-thumbnail {
			width: 300px;
			height: 300px;
		}
	}
}

.ttm-box-view-overlay {
	position: relative;
	overflow: hidden;
	&:before {
		position: absolute;
		content: "";
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		z-index: 1;
		opacity: 0;
		-webkit-transition: all .4s ease-out;
		transition: all .4s ease-out;
	}
}
.ttm-box-view-content-inner {
	position: relative;
	overflow: hidden;
	&:before {
		position: absolute;
		content: "";
		left: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		z-index: 1;
		opacity: 0;
		-webkit-transition: all .4s ease-out;
		transition: all .4s ease-out;
	}
}
div {
	&:hover {
		>.ttm-box-view-overlay {
			&:before {
				opacity: .8;
			}
		}
		>.ttm-box-view-content-inner {
			&:before {
				opacity: .8;
			}
		}
	}
}

/* post-1*/
.featured-imagebox-post {
	overflow: visible;
	.featured-content {
		.post-meta {
			span {
				display: inline-block;
				position: relative;
				margin-right: 20px;
				font-size: 13px;
				line-height: 13px;
				font-style: italic;
				margin-bottom: 12px;
				&:last-child {
					margin-right: 0;
				}
				&:not(:last-child) {
					&:after {
						position: absolute;
						background-color: currentColor;
						display: inline-block;
						content: "";
						background-color: #898a9c;
						width: 1px;
						height: 100%;
						border-radius: 50%;
						vertical-align: middle;
						top: 0;
						opacity: .2;
						right: -12px;
					}
				}
				i {
					padding-right: 5px;
					font-size: 12px;
				}
			}
		}
	}
	.ttm-box-post-date {
		.ttm-entry-date {
			color: #fff;
			display: inline-block;
			padding: 20px 8px;
			z-index: 2;
			font-size: 15px;
		}
	}
}
.ttm-box-post-date {
	.ttm-entry-date {
		.entry-month {
			font-size: 16px;
			line-height: 1;
			display: block;
			font-weight: 700;
			text-transform: capitalize;
		}
		.entry-year {
			font-size: 16px;
			line-height: 1;
			display: block;
			font-weight: 700;
			text-transform: capitalize;
		}
	}
}
.post-category {
	display: inline-block;
	border-radius: 6px;
	font-size: 14px;
	line-height: 1;
	padding: 6px 10px;
	color: #fff;
	margin-bottom: 15px;
}
.post-bottom.ttm-post-link {
	display: flex;
}
.ttm-post-format-video {
	position: relative;
	overflow: hidden;
	padding-bottom: 55.25%;
	padding-top: 25px;
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
}

/* post-1 */
.featured-imagebox-post.style1 {
	background-color: #fff;
	transition: all .4s;
	box-shadow: 2px 0px 20px 0 rgba(0, 0, 0, 0.08);
	.post-header {
		display: flex;
		align-items: center;
		margin-bottom: 17px;
	}
	.featured-content {
		padding: 30px 35px 35px;
		position: relative;
	}
	.post-meta {
		display: block;
		padding: 5px 0px 0;
	}
	.ttm-box-post-date {
		position: absolute;
		left: 20px;
		top: 20px;
		height: 60px;
		width: 60px;
		font-weight: 500;
		font-size: 15px;
		line-height: 19px;
		padding-top: 11px;
		z-index: 2;
		color: $theme-WhiteColor;
		background-color: $theme-SkinColor;
		text-align: center;
		&:before {
			position: absolute;
			content: "";
			width: 0;
			height: 0;
			left: auto;
			right: 0;
			bottom: -15px;
			border: 0 solid transparent;
			border-bottom-width: 15px;
			border-top-width: 0px;
			border-right: 10px solid $theme-SkinColor;
		}
		.ttm-entry-date {
			display: block;
			padding: 0;
			margin: 0;
			font-size: 16px;
			line-height: 20px;
			display: block;
			font-family: 'Noto Sans JP', sans-serif;
			font-weight: 500;
			.entry-month {
				font-size: 14px;
				font-weight: 400;
				margin-top: 5px;
				text-transform: uppercase;
			}
		}
	}
}

/* services-1 */
.featured-imagebox-service.style1 {
	.ttm-top-imagebox {
		width: 40%;
		@media (max-width: 991px) {
			width: 100%;
		}
	}
	.ttm-wrap-cell {
		display: table-cell;
		vertical-align: middle;
		@media (max-width: 991px) {
			display: block;
		}
	}
	.featured-content {
		padding-left: 80px;
		vertical-align: top;
		width: 80%;
		@media (max-width: 991px) {
			width: 100%;
			padding-left: 0;
		}
		.process-num {
			@media (max-width: 991px) {
				margin-top: 30px;
				margin-bottom: 30px;
			}
			span.number {
				font-size: 125px;
				line-height: 135px;
				-webkit-text-stroke-width: 2px;
				-webkit-text-fill-color: transparent;
				color: $theme-DarkColor;
				@media (max-width: 991px) {
					font-size: 80px;
					line-height: 50px;
				}
			}
		}
	}
	.featured-title h2 {
		font-size: 68px;
		line-height: 78px;
		@media (max-width: 991px) {
			font-size: 40px;
    		line-height: 50px;
		}
	}
	.featured-desc {
		margin-bottom: 25px;
    	padding-bottom: 30px;
	}
}
.featured-imagebox-service.style1:hover .featured-thumbnail img {
	transform: unset;
}

/* services-2 */
.featured-imagebox-service.style2 {
	background-color: $theme-SkinColor;
	border-radius: 8px;
	border-style: solid;
    border-width: 1px;
    border-color: #097ae9;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    padding: 45px 30px 45px;
	h2 {
		font-size: 24px;
		font-weight: 600;
		line-height: 34px;
		color: $white;
		text-align: center;
		margin-top: 10px;
	}
	.featured-thumbnail {
		margin-right: 12px;
	}
	.featured-content {
		.featured-title {
			h3 a {
				margin-bottom: 0;
				font-size: 24px;
				font-family: "Caveat",Sans-serif;
				font-weight: 700;
				line-height: 34px;
				color: $theme-WhiteColor;	
			}
		}
	}
	.featured-image-box-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.featured-content .featured-title h3 a:after {
		content: '';
		position: absolute;
		border-bottom: 2px solid #a4cef7;
		width: 100%;
		top: auto;
		bottom: 0;
		left: 0;
	}
	.ttm-iconbox {
		text-align: center;
	}
	.ttm-btn {
		padding: 12px 20px;
		background-color: transparent;
		border: 2px solid #fff;
		border-radius: 8px;
		color: $white;
		text-align: center;
	}
}

/* services-3 */
.featured-imagebox-service.style3 {
	border-radius: 8px;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    padding: 50px 30px 30px;
	h2 {
		font-size: 24px;
		font-weight: 600;
		line-height: 34px;
		color: $black;
		text-align: center;
		margin-top: 10px;
	}
	.featured-thumbnail {
		margin-bottom: 10px;
	}
	.featured-content {
		.featured-title {
			h3 a {
				margin-bottom: 0;
				font-size: 24px;
				font-family: "Caveat",Sans-serif;
				font-weight: 700;
				line-height: 34px;
				color: $theme-DarkColor;
			}
		}
	}
	.featured-image-box-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
	}
	.featured-content {
		.featured-title {
			h3 a {
				font-size: 28px;
				line-height: 38px;
			} 
			h3 a:after {
				content: '';
				position: absolute;
				border-bottom: 2px solid #000000;
				width: 75%;
				top: auto;
				bottom: 0;
				left: auto;
				right: 28px;
			}
		}
		.ttm-mail {
			h3 {
				padding-bottom: 10px;
				color: $black;
				font-size: 18px;
				font-weight: 700;
				line-height: 28px;
				display: flex;
				@media (max-width: 991px) {
					display: block;
				}
				a {
					font-size: 18px;
					font-weight: 700;
					line-height: 28px;
					text-decoration: underline;
				}
			}
		}
	} 
	.ttm-iconbox {
		text-align: center;
	}
	.ttm-btn {
		padding: 12px 20px;
		background-color: transparent;
		border: 2px solid #000;
		border-radius: 8px;
		color: $black;
		text-align: center;
	}
}
.featured-imagebox-service.style3 .featured-thumbnail img {
	width: auto;
}
.featured-imagebox-service.style3:hover .featured-thumbnail img {
	transform: unset;
}
@media only screen and (max-width: 991px) {
.featured-imagebox-service.style3 .featured-content .featured-title h3 a:after {
		display: none;
	}
}

/* services-4 */
.featured-imagebox-service.style4 {
	border-radius: 8px;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    padding: 110px 30px 110px;
	@media (max-width: 991px){
		padding: 195px 0 60px;
	}
	h2 {
		font-size: 24px;
		font-weight: 600;
		line-height: 34px;
		color: $white;
		text-align: left;
		margin-top: 10px;
		margin-left: 20px;
	}
	h3 {
		font-weight: 600;
		font-size: 18px;
		line-height: 28px;
		text-align: left;
		color: $white;
		margin-left: 20px;
	}
	p {
		font-size: 18px;
    	color: $white;
		font-weight: 400;
		display: inline-block;
	}
	i {
		font-weight: 600;
		font-size: 50px;
		line-height: 28px;
		text-align: left;
		color: $white;
		margin-left: 20px;
	}
	.featured-thumbnail {
		margin-bottom: 10px;
	}
	.featured-content {
		.featured-title {
			h3 {
				font-family: "Caveat",Sans-serif;
				color: $theme-WhiteColor;
				a {
				margin-bottom: 0;
				font-size: 24px;
				font-family: "Caveat",Sans-serif;
				font-weight: 700;
				line-height: 34px;
				color: $theme-WhiteColor;
				}
			}
		}
	}
	.featured-image-box-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
	}
	.featured-content {
		.featured-title {
			h3 a:after {
				content: '';
				position: absolute;
				border-bottom: 2px solid #FFFFFF;
				width: 48%;
				top: auto;
				bottom: 0;
				left: auto;
				right: 105px;
				@media (max-width: 991px){
					content: unset;
				}
			}
		}
		.ttm-mail {
			h3 {
				padding-bottom: 10px;
				color: $white;
				font-size: 18px;
				font-weight: 700;
				line-height: 28px;
				@media (max-width: 991px) {
					display: block;
				}
				a {
					font-size: 18px;
					font-weight: 400;
					line-height: 28px;
					text-decoration: underline;
				}
				a:hover {
					color: rgba(255,255,255,.85);
				}
			}
		}
	} 
	.ttm-iconbox {
		text-align: center;
	}
	.ttm-btn {
		padding: 12px 20px;
		background-color: transparent;
		border: 2px solid $white;
		border-radius: 8px;
		color: $white;
		text-align: center;
		margin-top: 20px;
	}
}
.featured-imagebox-service.style4 .featured-thumbnail img {
	width: auto;
}
.featured-imagebox-service.style4:hover .featured-thumbnail img {
	transform: unset;
}

/* portfolio-01 */
.featured-imagebox-portfolio.style1 {
	overflow: visible;
	margin: 15px 3px 40px;
	.featured-content {
		text-align: left;
	}
	.featured-title {
		p {
		margin-bottom: 0;
		color: $black;
		}
	}
	.featured-thumbnail {
		position: relative;
		z-index: 0;
		display: block;
	}
    img {
		transition: transform .5s ease-in-out;
		width: 100%;
		border-radius: 8px;
	}
	.ttm-box-view-overlay{
		text-align: center;
		position: absolute;
		left: 30px;
		right: 30px;
		bottom: -40px;
		top: auto;
		transition: .5s all linear 0ms;
		padding: 20px 30px 5px 21px;
		background-color: $theme-GreyColor;
		transition: all .5s ease;
		border-radius: 8px;
		&:before {
			position: relative;
		}
	}
	.ttm-media-link {
		a {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			text-align: center;
			color: rgb(255, 255, 255);
			width: 40px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			border-radius: 6px;
			font-size: 16px;
			transform: scale(0);
			opacity: 0;
			z-index: 2;
			margin: 0 auto;
			margin-top: -22px;
			transition: all 0.45s ease 0ms;
		}
	}
	.ttm-iconbox {
		position: absolute;
		bottom: 20px;
    	right: 20px;
		transition: all .5s ease-in-out;
		a {
			height: 35px;
			width: 35px;
			line-height: 15px;
			margin-bottom: 10px;
			text-align: center;
			color: $black;
			position: relative;
			display: inline-block;
			vertical-align: top;
			font-size: 16px;
			border-radius: 50%;
			border: 2px solid $black;
		}
		i {
			font-size: 20px;
		}
	}  
	&:hover {
		.ttm-media-link {
			a {
				opacity: 1;
				transform: scale(1);
			}
		}
		.featured-thumbnail {
			border-radius: 8px;
		}
	}
}
.featured-imagebox-portfolio.style1:hover {
	.ttm-iconbox {
		a {
			transform: rotate(45deg);
		}
	}
	.featured-thumbnail {
		img {
			border-radius: 8px;
		}
	} 
}

@media (max-width: 991px){
	.featured-imagebox-portfolio.style1 .featured-content .featured-title h3 {
		font-size: 18px;
    	line-height: 25px;
	}
}

/* portfolio-02*/
.featured-imagebox-portfolio.style2 {
	.ttm-media-link {
		a {
			position: relative;
			width: 36px;
			height: 36px;
			line-height: 36px;
			text-align: center;
			margin: 0 5px;
			top: 20px;
			opacity: 0;
			font-size: 14px;
			border-radius: 50%;
			font-weight: normal;
			color: #fff;
			border: 1px solid #8D909B;
		}
		display: flex;
		position: relative;
		padding-top: 15px;
		padding-bottom: 4px;
		&:after {
			position: absolute;
			left: 0;
			display: block;
			top: 0;
			height: 1px;
			width: 100%;
			content: "";
			transform: scaleX(0);
			background-color: #8D909B;
		}
	}
	&:hover {
		.ttm-media-link {
			a {
				top: 0;
				opacity: 1;
				transition-property: all;
				transition-duration: 0.2s;
				transition-timing-function: linear;
				transition-delay: 0.5s;
				&:hover {
					transition: all .4s;
				}
			}
			&:after {
				transition-property: all;
				transition-duration: 0.3s;
				transition-timing-function: cubic-bezier(0.63, 0.01, 0, 1.39);
				transition-delay: 0.65s;
				transform: scaleX(2);
			}
		}
		.featured-content {
			transition-property: all;
			transition-duration: 0.4s;
			transition-timing-function: linear;
			transition-delay: 0s;
			transform: scale(1);
			opacity: 1;
		}
	}
	.featured-content {
		transform: scale(1.1);
		opacity: 0;
		position: absolute;
		top: 7px;
		left: 7px;
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 1;
		justify-content: center;
		width: calc(100% - 2 * 7px);
		height: calc(100% - 2 * 7px);
	}
}

/*portfolio style-3 */

.featured-imagebox-portfolio.style3 { position: relative; overflow: visible; }
.featured-imagebox-portfolio.style3 .ttm-post-item { position: relative; }
.featured-imagebox-portfolio.style3:hover .featured-content { opacity: 0; }
.featured-imagebox-portfolio.style3 .item-figure { 
	height: 505px; 
	@media only screen and (min-width: 1201px) and (max-width: 1900px) {
		.featured-imagebox-portfolio.style3 .item-figure {
			height: auto;
		}
	}
}
.featured-imagebox-portfolio.style3 .featured-content .ttm-icon-box a { font-size: 20px; color: $white; }
.featured-imagebox-portfolio.style3:before {
    border: 0 solid rgba(255,255,255,.2);
    border-width: 0 0 0 2px;
    position: absolute;
    padding: 0 !important;
    left: 0;
    height: calc(100% + 60px);
    width: 1px;
    content: '';
}
.featured-imagebox-portfolio.style3:hover .readmore_btn {
    opacity: 1;
    clip-path: inset(0 0 0 0);
    -webkit-clip-path: inset(0 0 0 0);
}
.featured-imagebox-portfolio.style3 .readmore_btn {
    text-align: center;
    padding: 18px 15px;
    background-color: $theme-SkinColor;
    position: absolute;
    bottom: -78px;
    left: 0;
    right: 0;
    opacity: 0;
    clip-path: inset(0 100% 0 0);
    -webkit-clip-path: inset(0 100% 0 0);
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    font-weight: 700;
    z-index: 1;
}
.featured-imagebox-portfolio.style3 .readmore_btn a {
    position: relative;
    color: $white !important;
    margin-left: -18px;
}
.featured-imagebox-portfolio.style3 .readmore_btn a:before {
    position: absolute;
    content: '';
    height: 1px;
    width: calc(100% + 17px);
    background-color: $theme-WhiteColor;
    bottom: -5px;
}
.featured-imagebox-portfolio.style3 .readmore_btn a:after {
    position: absolute;
    content: "\f103";
    font-family: "flaticon_printing";
    font-size: 12px;
    right: -16px;
    transition: all .1s;
}
.featured-imagebox-portfolio.style3:hover .featured-content {
    opacity: 0;
}
.featured-imagebox-portfolio.style3 .featured-content {
    position: absolute;
    z-index: 1;
    left: 70px;
    right: 60px;
    bottom: 32px;
    z-index: -1;
}
.portfolio-section .slick_slider.row.g-0 [class*='col-'] {
    padding: 0;
	display: block;
    float: left;
    height: 100%;
    min-height: 1px;
}
.portfolio-section .slick-slider .slick-track, .portfolio-section .slick-slider .slick-list {
    overflow: visible;
}
.featured-imagebox-portfolio.style3 .featured-title h3 {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 34px;
    position: relative;
    text-align: left;
    color: #fff;
}
.featured-imagebox-portfolio.style3 .featured-title h3 a {
    color: #fff;
}
.featured-imagebox-portfolio.style3 .featured-desc p {
    color: rgba(255,255,255,.85);
}
.featured-imagebox-portfolio.style3 .ttm-icon-box {
    display: inline-block;
}
.featured-imagebox-portfolio.style3 .ttm-icon-box i {
    color: #fff;
    font-size: 20px;
}
.featured-imagebox-portfolio.style3 .readmore_btn a:hover:before {
    background: #fff;
}
.featured-imagebox-portfolio.style3:hover .ttm-btn {
    opacity: 1;
}
.featured-imagebox-portfolio.style3:after {
    position: absolute;
    content: '';
    border: 0px solid rgba(255, 255, 255 , 0.20);
    border-width: 2px 0 0 0;
    left: 0;
    height: 1px;
    width: 100%;
    top: 0px;
}
.featured-imagebox-portfolio.style3 .featured-thumbnail {
    opacity: 0;
    transition: all 0.3s ease 0s;
    min-width: 100%;
    width: 100%;
    opacity: 0;
    clip-path: inset(0 100% 0 0);
    -webkit-clip-path: inset(0 100% 0 0);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    z-index: 1;
    top: -46px;
    cursor: pointer;
	
}
@media only screen and (min-width: 1201px) and (max-width: 1900px) {
	.featured-imagebox-portfolio.style3 .item-figure { height: auto ; }
	.featured-imagebox-portfolio.style3 .featured-thumbnail { top: 0; }
	.featured-imagebox-portfolio.style3 .readmore_btn { bottom: -63px;}
}
@media (max-width: 1199px) {
	.featured-imagebox-portfolio.style3 .item-figure { height: auto; }
	.featured-imagebox-portfolio.style3 .featured-thumbnail { top: 0; }
	.featured-imagebox-portfolio.style3 .readmore_btn { bottom: -63px;}
}
.featured-imagebox-portfolio.style3 .featured-thumbnail img {
	border-radius: 0;
}
.featured-imagebox-portfolio.style3 .featured-content {
    position: absolute;
    z-index: 1;
    left: 70px;
    right: 60px;
    bottom: 32px;
    z-index: -1;
}
.featured-imagebox-portfolio.style3 .featured-content .featured-title h3 {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 34px;
    position: relative;
    text-align: left;
    color: #fff;
}
.featured-imagebox-portfolio.style3:hover .featured-thumbnail {
    opacity: 1;
    clip-path: inset(0 0 0 0);
    -webkit-clip-path: inset(0 0 0 0);
}
.featured-imagebox-portfolio.style3:hover .ttm-icon-box {
    opacity: 1;
}
.featured-imagebox-portfolio.style3:hover .featured-thumbnail img {
    transform: unset;
	border-radius: 0;
}

/*----------------------------------------*/
/*  portfolio-single  
/*---------------------------------------------------------------*/
.ttm-pf-single-detail-box{
    padding: 35px 35px 25px;
    background-color: $theme-DarkColor;
    box-shadow: 0 0 15px 0 rgba(40,61,88,.07);
    border-bottom: 3px solid $white;
	border-radius: 8px;
    ul{
        margin: 0;
        padding: 0;
        li{
            position: relative;
            padding: 15px 0 15px 0px;
            font-size: 15px;
            margin-left: 3px;
			color: rgba(255,255,255,.7);
            i, svg{margin-right: 10px;font-size: 16px;color: $white;}
            span{font-weight: 500;margin-right: 5px;}
            &{border-bottom: 1px solid rgba(255,255,255,.2);}
			span {
				color: $white;
			}
        }
    }
	.ttm-pf-single-title {
		h2 {
			color: $white;
			font-size: 24px;
    		line-height: 34px;
		}
	}
	.ttm-social-links-wrapper ul li {
		border-bottom: none;
		a {
			i {
				font-size: 15px;
				margin-right: 0;
			}
			display: inline-block;
			font-size: 12px;
			width: 35px;
			height: 35px;
			text-align: center;
			line-height: 35px;
			border: 1px solid #FFFFFF40;
			border-radius: 50%;
			margin-right: 5px;
		}
		a:hover {
			background-color: $theme-SkinColor;
			color: $theme-WhiteColor;
			border: 1px solid $theme-SkinColor;
		}
	}
}

/*===========================
26. Blog Page Style CSS
=============================*/

/** Classic_Blog **/
.post-featured-wrapper{ 
    position: relative; 
  }
article.ttm-blog-classic {
    @extend .post-featured-wrapper;
    margin-bottom: 35px;
    background-color: #fff;
    &:last-child {margin-bottom: 15px;}
    .ttm-box-post-date {
        .entry-month, .entry-date {
            display: block;
            font-size: 22px;
            line-height: 18px;
        }
    }
    .ttm-blog-classic-content {
        padding: 20px 0px 30px;
        background: #fff;
        z-index: 1;
        position: relative;
    }
    
    .post-meta{
        padding: 0 0 15px;
        display: inline-block;
        margin-bottom: 0;
        position: relative;
        margin: 0;
    }
    .entry-header .entry-title {
        font-size: 35px;
		line-height: 44px;
		margin-bottom: 10px;
    }
    .ttm-blogbox-desc-footer {
        margin-top: 15px;
        clear: both;
        overflow: hidden;
        a{color: #fff}
    }
    .ttm-commentbox i, svg{margin-right: 5px;color:#fff;}
    
}

.ttm-blog-single-content {
    blockquote {
        display: block;
        background-color: $theme-GreyColor;
        padding: 51px 40px 42px 150px;
        position: relative;
        font-weight: 500;
        margin: 35px 0 50px 0;
        font-style: normal;
		border-radius: 8px;
        font-size: 18px;
        line-height: 30px;
        font-style: italic;
        color: #6e6e6e;
        border-left: 4px solid #fff;        
        &::before{
            content: "\f11c";
			font-family: 'flaticon';
			position: absolute;
			bottom: 0;
			top: 25%;
			left: 8%;
			right: 0;
			font-size: 40px;
			font-weight: 400;
			font-style: normal;
			color: #fff;
			background-color: $theme-SkinColor;
			width: 60px;
			height: 60px;
			line-height: 60px;
			text-align: center;
			border-radius: 8px;
        }
		.qoute-text {
			font-weight: 700;
			font-size: 20px;
			line-height: 32px;
			padding-bottom: 10px;
			color: $black;
			font-style: normal;
		}
		cite {
			font-size: 15px;
			margin-top: 27px;
			position: relative;
			font-weight: 700;
			color: $black;
			display: inline;
			font-style: normal;
			padding-right: 15px;
		}
		.cite-righttext {
			font-weight: 400;
			font-size: 15px;
			display: inline;
			margin-bottom: 10px;
    		color: $black;
			i {
				display: inline-block;
				width: 13px;
				margin: 0 2px;
				font-size: 15px;
				color: #ffae00;
				padding-right: 20px;
			}
		}
    }
	.entry-content {
		ul.ttm-list {
			display: inline-block;
			li {
				i {
					color: $theme-SkinColor;
					padding-right: 7px;
					font-size: 22px;
					vertical-align: text-top;
				}
			}
			.ttm-list-li-content {
				padding-left: 30px;
				font-weight: 600;
				color:$black;
				font-size: 16px;
    			line-height: 26px;
			}
			.ttm-blog-single .ttm-blog-single-content .social-media-block {
				padding: 15px 0 30px;
				margin-top: 30px;
				border-top: 1px solid #eee;
				border-bottom: 1px solid #eee;
			}
		}
	}
	.social-media-block {
		margin-top: 30px;
		background-color: $theme-GreyColor;
		padding: 15px 30px 20px;
		border-radius: 8px;
		span.ttm-tags-links-title {
			font-size: 20px;
			color: $black;
			margin-right: 15px;
			font-weight: 700;
			margin-bottom: 10px;
			display: inline-block;
		}
		.ttm_tag_lists a {
			padding: 7px 20px;
			display: inline-block;
			border-radius: 8px;
			position: relative;
			background-color: $theme-WhiteColor;
			margin-right: 5px;
			color: $black;
			margin-bottom: 5px;
		}
		.ttm-social-share-wrapper ul li a {
			border: 1px solid #c4c4c4;
			height: 35px;
			width: 35px;
			line-height: 34px;
			text-align: center;
			display: block;
			font-size: 13px;
			border-radius: 50%;
			color: #666666;
		}
	}
}

@media (max-width: 575px){
	.ttm-blog-single-content blockquote::before {
		content: unset;
	}
	.ttm-blog-single-content blockquote {
		padding: 30px;
	}
}

.ttm-blog-single-content, .ttm-blog-classic{
    .post-meta .ttm-meta-line {
        font-size: 15px;
		line-height: 15px;
		font-weight: 700;
		display: inline-block;
		color: $black;
		padding-right: 35px;
		position: relative;
        &:not(:last-child):after {
            position: relative;
			display: inline-block;
			content: "";
			height: 15px;
			width: 2px;
			margin-top: 2px;
			background-color: $theme-SkinColor;
			right: -17px;
			top: 3px;
			transform: rotate(25deg);
        }
		a {
			color: $theme-SkinColor;
		}
        i, svg {
            padding-right: 7px;
            font-size: 20px;
            color: #fff;
            vertical-align: middle;
        }
    }
}
.ttm-tag-list span a {
    padding: 7px 15px;
    background-color: #fff;
    display: inline-block;
    margin-right: 6px;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 7px;
}


/*blog*/

.featured-imagebox-blog.style1 {
    z-index: 1;
    text-align: left;
	padding-bottom: 60px;
	@media (max-width: 991px){
		padding-bottom: 0;
	}
	.featured-thumbnail {
		border-radius: 8px;
	}
    .ttm-box-post-date {
		background-color: $theme-SkinColor;
		color: #fff;
		width: 50px;
		height: 50px;
		line-height: 50px;
		bottom: -20px;
		top: auto;
		left: 25px;
		position: absolute;
		border-radius: 8px;
		font-size: 24px;
		line-height: 20px;
		font-weight: 700;
		padding-top: 5px;
		text-align: center;
	}
	.faetured-image-wrapper {
		position: relative;
	}
	.featured-title h3 {
		margin-top: 55px;
		a {
			color: $white;
			font-weight: 700;
			font-size: 24px;
			line-height: 30px;
		}
		a:hover {
			color: $theme-SkinColor;
		}
	}
}

/*style-2*/
.featured-imagebox-blog.style2 {
	margin: 15px 3px;
    .featured-thumbnail { 
		border-radius: 8px;
		img {
			width: 100%;
			-moz-transform: scale(1);
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);	
			transition: all .6s ease-in-out;
		}
	}
	&:hover{
        .featured-thumbnail img{transform: scale(1.05);}
    }
	.featured-content {
		padding-top: 23px;
    	padding-bottom: 53px;
	}
	.post-meta {
		margin-bottom: 9px;
		display: inline-block;
		position: relative;
		padding-right: 24px;
		:not(:last-child):after {
			position: relative;
			display: inline-block;
			content: "";
			height: 15px;
			width: 2px;
			margin-top: 2px;
			background-color: $theme-SkinColor;
			right: -17px;
			top: 3px;
			transform: rotate(25deg);
		}
		span.ttm-meta-line.byline {
			font-size: 15px;
			font-weight: 700;
			color: $theme-SkinColor;
		}
		.ttm-meta-line.category-link {
			display: inline-block;
			font-size: 15px;
			line-height: 23px;
			font-weight: 700;
			padding-left: 30px;
			color: $theme-DarkColor;
		}
	}
	.ttm-iconbox a:before {
		left: 0;
		font-size: 26px;
		line-height: 40px;
		bottom: 0px;
		color: #010b14;
		height: 43px;
		width: 43px;
		text-align: center;
		background-color: #FFFFFF;
		border: 2px solid #000000;
		border-radius: 50%;
		content: "\f103";
		position: absolute;
		font-family: 'flaticon';
		font-weight: 500;
		transition: .5s ease-in-out;
	}
}
.portfolio-imagebox-blog.style2 {
	margin: 15px 3px;
    .portfolio-thumbnail { 
		border-radius: 8px;
		img {
			width: 100%;
			-moz-transform: scale(1);
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);	
			transition: all .6s ease-in-out;
		}
	}
	&:hover{
        .portfolio-thumbnail img{transform: scale(1.05);}
    }
	.portfolio-content {
		padding-top: 23px;
	}
	.post-meta {
		margin-bottom: 9px;
		display: inline-block;
		position: relative;
		:not(:last-child):after {
			position: relative;
			display: inline-block;
			content: "";
			height: 15px;
			width: 2px;
			margin-top: 2px;
			background-color: $theme-SkinColor;
			right: -17px;
			top: 3px;
			transform: rotate(25deg);
		}
		span.ttm-meta-line.byline {
			font-size: 15px;
			font-weight: 700;
			color: $theme-SkinColor;
		}
		.ttm-meta-line.category-link {
			display: inline-block;
			font-size: 15px;
			line-height: 23px;
			font-weight: 700;
			padding-left: 30px;
			color: $theme-DarkColor;
		}
	}
	.ttm-iconbox a:before {
		left: 0;
		font-size: 26px;
		line-height: 40px;
		bottom: 0px;
		color: #010b14;
		height: 43px;
		width: 43px;
		text-align: center;
		background-color: #FFFFFF;
		border: 2px solid #000000;
		border-radius: 50%;
		content: "\f103";
		position: absolute;
		font-family: 'flaticon';
		font-weight: 500;
		transition: .5s ease-in-out;
	}
}
.portfolio-imagebox-blog:hover .ttm-iconbox a:before {
	transform: rotate(45deg);
}
.featured-imagebox-blog:hover .ttm-iconbox a:before {
	transform: rotate(45deg);
}

/* team-1 */
.featured-imagebox-team.style1 {
	margin: 10px 0px;
	.featured-thumbnail {
		img  {
			width: 100%;
			-moz-transform: scale(1);
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);	
			transition: all .6s ease-in-out;
			border-radius: 8px;
		}
	}
	&:hover{
        .featured-thumbnail img{transform: scale(1.05);}
    }
	.social-icon-wrapper {
		ul.social-icons {
		position: absolute;
		top: 20px;
		bottom: auto;
		left: 0;
		right: auto;
		padding: 0;
		margin: 0;
		background: 0 0;
		z-index: 2;
		text-align: center;
		left: 5%;
		li {
			opacity: 0;
			vertical-align: top;
			text-align: center;
			margin: 0 0 6px;
			-webkit-transform: translateX(-100%);
			-moz-transform: translateX(-100%);
			-ms-transform: translateX(-100%);
			-o-transform: translateX(-100%);
			transform: translateX(-100%);
			-webkit-transition: .4s;
			-o-transition: .4s;
			-moz-transition: .4s;
			transition: .4s;
			list-style: none;
		}
		a {
			height: 33px;
			width: 33px;
			line-height: 35px;
			display: block;
			color:#fff;
			background-color: $theme-SkinColor;
			border-radius: 50%;
			font-size: 13px;
			}
			li:nth-child(1) {
			-webkit-transition-delay: 0s;
			-moz-transition-delay: 0s;
			-o-transition-delay: 0s;
			transition-delay: 0s;
			display: block;
			}
			li:nth-child(2) {
				-webkit-transition-delay: .1s;
				-moz-transition-delay: .1s;
				-o-transition-delay: .1s;
				transition-delay: .1s;
				display: block;
			}
			li:nth-child(3) {
				-webkit-transition-delay: .2s;
				-moz-transition-delay: .2s;
				-o-transition-delay: .2s;
				transition-delay: .2s;
				display: block;
			}
			li:nth-child(4) {
				-webkit-transition-delay: .3s;
				-moz-transition-delay: .3s;
				-o-transition-delay: .3s;
				transition-delay: .3s;
				display: block;
			}
			li:last-child {
				margin-right: 0;
			}
		}
	}
	.featured-content {
		padding-top: 20px;
		.featured-content-box-inner p {
			font-weight: 600;
			color: $black;
			margin-bottom: 0;
		}
	}
	
}
.portfolio-imagebox-team.style1 {
	margin: 10px 0px;
	.portfolio-thumbnail {
		img  {
			width: 100%;
			-moz-transform: scale(1);
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);	
			transition: all .6s ease-in-out;
			border-radius: 8px;
		}
	}
	&:hover{
        .portfolio-thumbnail img{transform: scale(1.05);}
    }
	.social-icon-wrapper {
		ul.social-icons {
		position: absolute;
		top: 20px;
		bottom: auto;
		left: 0;
		right: auto;
		padding: 0;
		margin: 0;
		background: 0 0;
		z-index: 2;
		text-align: center;
		left: 5%;
		li {
			opacity: 0;
			vertical-align: top;
			text-align: center;
			margin: 0 0 6px;
			-webkit-transform: translateX(-100%);
			-moz-transform: translateX(-100%);
			-ms-transform: translateX(-100%);
			-o-transform: translateX(-100%);
			transform: translateX(-100%);
			-webkit-transition: .4s;
			-o-transition: .4s;
			-moz-transition: .4s;
			transition: .4s;
			list-style: none;
		}
		a {
			height: 33px;
			width: 33px;
			line-height: 35px;
			display: block;
			color:#fff;
			background-color: $theme-SkinColor;
			border-radius: 50%;
			font-size: 13px;
			}
			li:nth-child(1) {
			-webkit-transition-delay: 0s;
			-moz-transition-delay: 0s;
			-o-transition-delay: 0s;
			transition-delay: 0s;
			display: block;
			}
			li:nth-child(2) {
				-webkit-transition-delay: .1s;
				-moz-transition-delay: .1s;
				-o-transition-delay: .1s;
				transition-delay: .1s;
				display: block;
			}
			li:nth-child(3) {
				-webkit-transition-delay: .2s;
				-moz-transition-delay: .2s;
				-o-transition-delay: .2s;
				transition-delay: .2s;
				display: block;
			}
			li:nth-child(4) {
				-webkit-transition-delay: .3s;
				-moz-transition-delay: .3s;
				-o-transition-delay: .3s;
				transition-delay: .3s;
				display: block;
			}
			li:last-child {
				margin-right: 0;
			}
		}
	}
	.portfolio-content {
		padding-top: 20px;
		.portfolio-content-box-inner p {
			font-weight: 600;
			color: $black;
			margin-bottom: 0;
		}
	}
	
}
.featured-imagebox-team.style1:hover .social-icon-wrapper ul.social-icons li {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
	display: block;
}

.portfolio-imagebox-team.style1:hover .social-icon-wrapper ul.social-icons li {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
	display: block;
}

/* team-2 */
.featured-imagebox-team.style2 {
	.featured-thumbnail {
		border-radius: 8px;
		img  {
			width: 100%;
			-moz-transform: scale(1);
			-webkit-transform: scale(1);
			-ms-transform: scale(1);
			transform: scale(1);	
			transition: all .6s ease-in-out;
		}
	}
	&:hover{
        .featured-thumbnail img{transform: scale(1.05);}
    }
	.social-icon-wrapper {
		ul.social-icons {
		position: absolute;
		top: 20px;
		bottom: auto;
		left: 0;
		right: auto;
		padding: 0;
		margin: 0;
		background: 0 0;
		z-index: 2;
		text-align: center;
		left: 5%;
		li {
			opacity: 0;
			vertical-align: top;
			text-align: center;
			margin: 0 0 6px;
			-webkit-transform: translateX(-100%);
			-moz-transform: translateX(-100%);
			-ms-transform: translateX(-100%);
			-o-transform: translateX(-100%);
			transform: translateX(-100%);
			-webkit-transition: .4s;
			-o-transition: .4s;
			-moz-transition: .4s;
			transition: .4s;
			list-style: none;
		}
		a {
			height: 33px;
			width: 33px;
			line-height: 35px;
			display: block;
			color:#fff;
			background-color: $theme-SkinColor;
			border-radius: 50%;
			font-size: 13px;
			}
			li:nth-child(1) {
			-webkit-transition-delay: 0s;
			-moz-transition-delay: 0s;
			-o-transition-delay: 0s;
			transition-delay: 0s;
			display: block;
			}
			li:nth-child(2) {
				-webkit-transition-delay: .1s;
				-moz-transition-delay: .1s;
				-o-transition-delay: .1s;
				transition-delay: .1s;
				display: block;
			}
			li:nth-child(3) {
				-webkit-transition-delay: .2s;
				-moz-transition-delay: .2s;
				-o-transition-delay: .2s;
				transition-delay: .2s;
				display: block;
			}
			li:nth-child(4) {
				-webkit-transition-delay: .3s;
				-moz-transition-delay: .3s;
				-o-transition-delay: .3s;
				transition-delay: .3s;
				display: block;
			}
			li:last-child {
				margin-right: 0;
			}
		}
	}
	.featured-content {
		padding-top: 20px;
		.featured-content-box-inner p {
			font-weight: 600;
			color: $white;
			margin-bottom: 0;
		}
		.featured-title {
			h3 {
				a {
					color: $white;
				}
				a:hover {
					color: $black;
				}
			}
		}
	}
}
.featured-imagebox-team.style2:hover .social-icon-wrapper ul.social-icons li {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
	display: block;
}


/* ===============================================
    13.Progress-Bar
------------------------*/
.ttm-progress-bar {
	position: relative;
	&:not(:last-child) {
		margin-bottom: 30px;
	}
	.progressbar-title {
		font-size: 15px;
		line-height: 24px;
		margin-bottom: 8px;
		font-weight: 600;
		padding-right: 50px;
		text-transform: uppercase;
		font-family: $bodyfont;
	}
	.progress-bar {
		position: relative;
    	overflow: visible;
    	background-color: transparent;

		.progress-bar-rect-wrap-container {
			box-shadow: none;
			width: 100%;
			height: 100%;
			z-index: 1;
			padding: 2px;
			background-color: transparent;
			border: 1px solid #e3e3e3;

			.progress-bar-rect-wrapper {
				display: block;
				height: 4px;
				position: relative;
				border-radius: 6px;
				overflow: hidden;
				background-color: rgba(192, 192, 192, 0.34);

				.inner-rect-bar.addRectAnimate {
					background-color: $theme-SkinColor !important;
				}
			}

			.rect-progress-bar-percent {
				position: absolute;
				right: 0;
				z-index: 3;
				text-shadow: none;
				font-size: 14px !important;
				line-height: 20px;
				font-weight: 600;
				border-radius: 0;
				top: -30px;
				margin: 0 !important;
				display: block !important;
				overflow: hidden;
			}
		}
	}
}

/* progress-stle1*/
.ttm-progress-bar.style1 {
	background-color: transparent;
	margin-bottom: 20px;
	h3.progressbar-title {
		padding-left: 20px;
		font-size: 20px;
		font-weight: 700;
		text-transform: unset;
		margin-bottom: 0;
	}
	.progress-bar-rect-wrap-container {
		border:unset;
		.progress-bar-rect-wrapper {
			height: 5px;
		}
		.rect-progress-bar-percent {
			background: 0 0;
			position: relative;
			right: -15px;
			top: -35px;
			font-size: 14px;
			font-weight: 700;
			color: $white;
			background-color: $theme-SkinColor;
			border-radius: 6px;
			text-align: center;
			padding: 2px 6px;
			height: 27px;
			width: 42px;
			line-height: 25px;
			overflow: visible;
			@media (max-width: 991px){
				right: 0;
			}
		}
		.rect-progress-bar-percent:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-top: 10px solid $theme-SkinColor;
			border-right: 7px solid transparent;
			border-left: 7px solid transparent;
			left: 15px;
			right: 0;
			top: 27px;
			display: block;
		}
	}
}

/* ===============================================
    14.Testimonial
------------------------*/
/* ttm-testimonial-box-view-style1 */
.testimonials {
	position: relative;
	.testimonial-caption {
		h3 {
			font-size: 20px;
			line-height: 30px;
			display: block;
			font-weight: 500;
			margin-bottom: 0;
		}
	}
	.testimonial-content {
		blockquote {
			padding: 10px 25px;
			font-size: 20px;
			position: relative;
			line-height: 32px;
			font-style: italic;
			margin: 0;
		}
	}
}
.testimonial-caption {
	label {
		margin: 0;
		display: block;
		font-size: 14px;
		line-height: 20px;
	}
}
.star-ratings {
	ul {
		padding: 0;
		margin: 0;
		li.active {
			color: #ffea5c;
			i {
				color: #ffea5c;
			}
		}
	}
	li {
		font-size: 12px;
		min-width: 15px;
		letter-spacing: 1px;
		display: inline-block;
		color: #e0e8f0;
	}
}


/* ttm-testimonial-box-view-style1 */
.testimonials.style1 {
	background-color: $theme-GreyColor;
    padding: 65px 40px 45px 50px;
    margin-top: 55px;
    border-radius: 8px;
    border: 1px solid #c4c4c4;
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    text-align: left;
    position: relative;
	:before {
		content: "\f11c";
		font-family: "flaticon";
		position: absolute;
		top: -30px;
		right: 0;
		left: 50px;
		width: 54px;
		height: 54px;
		line-height: 54px;
		border-radius: 8px;
		background-color: $theme-SkinColor;
		font-size: 32px;
		bottom: auto;
		text-align: center;
		color: $theme-WhiteColor;
		-webkit-transition: all .5s ease 0s;
		-moz-transition: all .5s ease 0s;
		-ms-transition: all .5s ease 0s;
		-o-transition: all .5s ease 0s;
		transition: all .5s ease 0s;
	}
	&:hover {
		background-color: $theme-WhiteColor;
	}
	.testimonial-content {
		blockquote {
		position: relative;
		font-size: 18px;
		line-height: 32px;
		margin-bottom: 25px;
		font-weight: 700;
		text-align: left;
		padding: 0;
		font-style: normal;
		color: $black;
		}
		blockquote:before {
			content: unset;
		}
		.testimonial-caption {
			display: table-cell;
			vertical-align: middle;
			padding-left: 20px;
			text-align: left;
			label {
				font-size: 20px;
				margin-bottom: 0;
				-webkit-transition: all .5s ease 0s;
				-moz-transition: all .5s ease 0s;
				-ms-transition: all .5s ease 0s;
				-o-transition: all .5s ease 0s;
				transition: all .5s ease 0s;
				line-height: 28px;
				font-weight: 700;
				color: $black;
			}
		}
	}
	.testimonial-avatar {
		display: table-cell;
		vertical-align: middle;
		.testimonial-img {
			img {
				border-radius: 50%;
			}
		}
	}	
}


/* ===============================================
    15.Client-row  
------------------------*/
.client-box {
	position: relative;
	text-align: center;
	.ttm-client-logo-tooltip {
		margin-top: 45px;
		margin-bottom: 45px;
		position: relative;
		img {
			text-align: center;
			display: block;
			margin: 0 auto;
		}
		.client-thumbnail_hover {
			position: absolute;
			content: '';
			left: 0;
			right: 0;
			z-index: 1;
			-webkit-transition: .3s;
			-o-transition: .3s;
			transition: .3s;
		}
	}
	.ttm-client-logo-tooltip-inner {
		position: relative;
	}
	&:hover {
		.ttm-client-logo-tooltip {
			img {
				opacity: 1;
			}
			.client-thumbnail_hover {
				bottom: 0;
			}
		}
	}
}

/*------------------------------------------------------------------------------*/
/*  single_team
/*------------------------------------------------------------------------------*/ 
.ttm-team-member-single-content {
	h3 {
		font-size: 36px;
		font-weight: 700;
		line-height: 46px;
		.ttm-team-member-content {
			h3 { 
			font-size: 30px; 
			line-height: 1; 
			margin-bottom: 20px;
			}
		}  
	}
	.ttm-featured-wrapper img{
		border-radius: 8px;
	 }
}
.ttm-team-member-detail {
	.ttm-team-member-single-title {
		font-size: 36px;
		line-height: 46px;
		margin-bottom: 0;
		font-weight: 700;
	}
	.ttm-team-member-single-position {
		padding-top: 4px;
		font-size: 20px;
		line-height: 30px;
		font-weight: 600;
		color: $theme-SkinColor;
	}
	.ttm-short-desc { 
		margin: 23px 0 22px;
	}
	ul {
		margin: 0;
		padding: 0;
		border-bottom: 1px solid #c4c4c4;
	}
	ul.social-icons {
		border-bottom: none;
	}
	.ttm-social-links-wrapper ul li {
		a {
			display: block;
			font-size: 12px;
			width: 35px;
			height: 35px;
			text-align: center;
			line-height: 35px;
			border: 1px solid $black;
			border-radius: 50%;
			margin-right: 5px;
		}
		a:hover {
			background-color: $theme-SkinColor;
			color: $theme-WhiteColor;
			border: 1px solid $theme-SkinColor;
		}
	} 
}
.ttm-team-details-list {
	li {
		position: relative;
		font-size: 15px;
		line-height: 24px;
		padding: 12px 30px 15px 0;
		display: inline-block;
	}
	.ttm-team-list-title,
	.ttm-team-list-value,
	.ttm-team-list-value a {
		display: inline-block;
    	color: var(--body-font-color);
	}
	.ttm-team-list-title  {
		position: relative;
		font-size: 15px;
		line-height: 26px;
		color: $theme-DarkColor;
		font-weight: 700;
		padding-right: 5px;
	}
}


/* ===============================================
    16.Accordion
------------------------*/
.accordion { position: relative; }

/* ttm-style-classic */
.accordion .toggle.ttm-toggle_style_classic {
    padding-bottom: 0px;
    border: 2px solid #e6e6e6;
    position: relative;
    margin-bottom: 30px;
}
.accordion .toggle.ttm-toggle_style_classic .toggle-content:after {
    position: absolute;
    content: '';
    background-color: $theme-SkinColor;
    width: 2px;
    height: 100%;
    top: 0;
    left: -2px;
}
.accordion .toggle.ttm-toggle_style_classic:last-child{ margin-bottom: 0; }
.accordion .toggle.ttm-toggle_style_classic .toggle-content{
    padding:  0px 20px 10px;
    margin-top: 0;
}
.accordion .toggle.ttm-toggle_style_classic .toggle-title {
    overflow: hidden;
}
.accordion .toggle.ttm-toggle_style_classic .toggle-title a {
    padding: 18px 40px 22px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: color 0s ease-in-out;
    -o-transition: color 0s ease-in-out;
    transition: color 0s ease-in-out;
    border: none;
    display: block;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    position: relative;
}
.accordion .toggle.ttm-toggle_style_classic .toggle-title a:hover {color: $theme-DarkColor;}
.accordion .toggle.ttm-toggle_style_classic .toggle-title a.active:after {
    content: "\f146";
}
.accordion .toggle.ttm-toggle_style_classic .toggle-title a:after {
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    right: 20px;
    top: 20px;
    display: inline-block;
    content: "\f358";
    border-radius: 50%;
    font-size: 12px;
    line-height: 10px;
}
.accordion .toggle-content.show {
    display: block !important;
}
.accordion .toggle.ttm-toggle_style_classic .toggle-title a:after{ 
    top: 37%;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    color: $theme-DarkColor;
}
.accordion .toggle.ttm-toggle_style_classic .toggle-title a.active:after{
    color: $theme-SkinColor;
}
.toggle .toggle-title.ttm-toggle_style_classic a.active{ margin: -1px; }

.accordion .toggle.ttm-toggle_style_border .toggle-title{
    border : 1px solid #f1f1f1;
}
.accordion .toggle.ttm-toggle_style_border .toggle-title a{
    padding: 12px 20px;
}
.accordion .toggle.ttm-toggle_style_border .toggle-content{
    padding: 14px 20px;
    border : 1px solid #f0f0f0;
}
.accordion .toggle.ttm-control-left-true .toggle-title a{
    padding-right: 20px;
    padding-left: 48px;
}
.accordion .toggle.ttm-control-left-true .toggle-title a:after{
    left: 20px;
}
.accordion .alignleft{ margin:  .375em 1.75em 0 0; }


/* ===============================================
    15.Wrap-Form
------------------------*/
.wrap-form {
	label {
		width: 100%;
		margin: 0;
		font-size: 14px;
		line-height: 1;
		position: relative;
		display: flex;
		flex-direction: column;

		>i {
			position: absolute;
			left: 20px;
			opacity: .9;
			font-size: 16px;
			z-index: 1;
			top: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		input, textarea, select {
			width: 100%;
			height: 100%;
			padding: 10px 20px;
			padding-left: 50px;
    		font-size: 14px;
			line-height: 24px;
    		border-color: transparent;
			border-radius: 8px;
    		background-color: $theme-GreyColor;
		}
		button {
			height: 100%;
			width: 100%;
		}
		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			letter-spacing: 0;
			outline: none;
			line-height: 24px;
			text-indent: 20px;
			padding: 10px 20px;
			padding-left: 25px;
			background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
			background-position: calc(100% - 22px) calc(1em + 7px), calc(100% - 17px) calc(1em + 7px), calc(100% - 3.5em) 2.5em;
			background-size: 5px 5px, 5px 5px, 1px 1.5em;
			background-repeat: no-repeat;

			
		}
		span {
			line-height: normal;
		}
	}
}


.bg-theme-GreyColor {
	.wrap-form {
		label {
			input, textarea, select {
				background-color: #fff;
			}
		}
	}
}

.wrap-form.search_Form  {
	display: flex;
	justify-content: space-between;

	label > {

		i {
			font-size: 22px;
		}
	}

	@media (max-width: 767px){
		display: block;
	}

	button {
		flex: 1 0 auto;

		@media (max-width: 767px){
			width: 100%;
		}
	}
	&.style2 {
		display: block;
		label {
			input, textarea {
				padding: 20px;
				font-size: 16px;
				font-weight: 600;
				border-radius: 6px;

			}
			select {
				font-size: 16px;
				font-weight: 600;
				padding: 24px 20px 25px;
				border-radius: 6px;
				text-indent: 0;
				background-position: calc(100% - 25px) calc(1em + 14px), calc(100% - 20px) calc(1em + 14px), calc(100% - 3.5em) 2.5em;
			}
			button { width: 100%; }
		}
	}
}


.wrap-form.submit_Form  {

	label {
		margin: 12px 0;
	}
	input, select {
		padding: 12px 15px;
	}
	input[type="file"] {
		padding: 0;
		border: 0;

		&::-webkit-file-upload-button {
			background-color: transparent;
			padding: 10px 15px;
			border: 1px solid rgba(119,119,119,.2);
			border-radius: 6px;
			margin-right: 15px;
		  }
	}
	textarea { 
		padding: 30px 30px;
		border: 0;
		margin-top: 12px;
		box-shadow: 0 0 9px 0 rgba(29, 33, 67, 0.10); 
	}
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		letter-spacing: 0;
		padding: 15px 15px 16px;
		text-indent: 0;
		background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
		background-position: calc(100% - 22px) calc(1em + 6px), calc(100% - 17px) calc(1em + 6px), calc(100% - 3.5em) 2.5em;
		background-size: 5px 5px, 5px 5px, 1px 1.5em;
		background-repeat: no-repeat;
	}

}

.wrap-form.contact_form  {
	input, select, textarea, button {
		padding: 20px 20px;
		line-height: 1.3;
		border-radius: 6px;
	}
}

.wrap-form.login_form {
	label {
		margin: 15px 0;

		input, textarea, select {
			margin-top: 5px;
			border: 0;
			height: auto;
			padding-left: 20px;
		}
	}
}
.social-account-button {
	display: inline-block;
	text-align: center;
	position: relative;
	font-size: 15px;
	line-height: 26px;
	font-weight: 500;
	padding: 11px 22px;
	border-radius: 3px;
	margin-top: 24px;

	i {
		display: inline-block;
		vertical-align: middle;
		text-align: inherit;
		margin-right: 10px;
		transition: .4s;
	}
	&:hover {
		opacity: .88;
	}
}
.text-form { 
	display: inline-block;
	padding-left: 28px;
	@media (max-width: 575px){
		padding-left: 0;
	}
	.query-text {
		font-size: 16px;
		color: $black;
		@media (max-width: 767px){
			margin-top: 20px;
		}
	}
}
.ttm-call-to-arrow {
    animation-name: arrow_up;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: arrow_up;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    margin-top: -14px;
    padding-left: 20px;
	@media (max-width: 1600px){
		display: none;
	}
	h3 {
		font-size: 15px;
		font-weight: 700;
		line-height: 25px;
		position: relative;
		margin-bottom: 0;
		left: -140px;
		animation: shake 24s linear infinite;
	}
	img {
		position: relative;
    	left: -110px;
		top: 20px;
		animation: shake 24s linear infinite;
	}
}
.ttm-call-to-arrow {
    animation-name: arrow_up;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: arrow_up;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    margin-top: -14px;
    padding-left: 20px;
	h3 {
		font-size: 15px;
		font-weight: 700;
		line-height: 25px;
		position: relative;
		margin-bottom: 0;
		left: -140px;
		animation: shake 24s linear infinite;
	}
	img {
		position: relative;
    	left: -110px;
		top: 20px;
		animation: shake 24s linear infinite;
	}
}
.ttm-call-to-arrow-1 {
    animation-name: arrow_up;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: arrow_up;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    margin-top: -14px;
    padding-left: 20px;
	h3 {
		font-size: 15px;
		font-weight: 700;
		line-height: 25px;
		position: relative;
		margin-bottom: 0;
		left: -100px;
    	top: 40px;
		animation: shake 24s linear infinite;
	}
	img {
		position: relative;
    	left: -70px;
   		top: 50px;
		animation: shake 24s linear infinite;
	}
}

@media (max-width: 991px){
	.ttm-call-to-arrow h3 {
		display: none;
	}
}

/* ===============================================
    18.Tab
------------------------*/
/* ttm-tab-style-01 */
.ttm-tabs.ttm-tab-style-01 {
	padding: 5px 30px 30px;
	background-color: $theme-GreyColor;
	margin-top: 30px;
	border-radius: 8px;
	@media (max-width: 991px){
		padding: 0px 0px 0px;
	}
	ul.tabs {
		width: 100%;
		padding: 0;
		margin: 0;
		li {
			position: relative;
			width: 100%;
			display: inline-block;
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.ttm-tabs.ttm-tab-style-01 {
	ul.tabs {
		margin: 0 -15px;
		display: flex;
		width: 100%;
		li {
			margin-right: 20px;
			a {
				display: block;
				cursor: pointer;
				padding: 15px 20px;
				font-size: 20px;
				line-height: 28px;
				font-weight: 700;
				position: relative;
				border: 0;
				margin: 15px 15px 25px;
				border-bottom: 1px solid $black;
				text-transform: capitalize;
				width: 100%;
				a:after {
					content: "\e64b";
					font-family: 'themify';
					position: relative;
					font-size: 15px;
					color: $black;
					right: 10px;
				}
			}
		}
		li.tab {
			a {
				&:after {
					content: "\e64b";
					font-family: 'themify';
					position: absolute;
					font-size: 15px;
					color: $black;
					right: 10px;
				}
			}
		}
		li.react-tabs__tab--selected {
			a {
				&:after {
					content: "\e648";
					font-family: 'themify';
					position: absolute;
					font-size: 15px;
					color: $black;
					right: 10px;
				}
				border-bottom-color: $black;
			}
		}
		.ttm-tabs.ttm-tab-style-01 ul.tabs li.react-tabs__tab--selected>a:after
			
		.ttm-tab-style1.ttm-elementor-tabs .ttm-elementor-tab-title.ttm-tab-active:after {
			content: '\f106';
			font-family: 'ttm-cleano-icons';
			position: absolute;
			right: 15px;
			color: #000;
			font-size: 25px;
			font-weight: 700;
		}
	}
	.ttm-list.style2 {
		display: block;
		margin-top: 0;
		li {
			padding-right: 0;
			 p {
				padding-left: 42px;
				padding-bottom: 10px;
			 }
		}
	}
	.content-tab {
		padding: 0;
		margin: 0;
	}
}
@media (max-width: 767px){
	.ttm-tabs.ttm-tab-style-01 ul.tabs {
		display: block;
	}
}
.inner-rect-bar.addRectAnimate {
	background-color: $theme-SkinColor !important;
}


/* ===============================================
    19.Boxes-Spacing
------------------------*/
.row.ttm-boxes-spacing-20px {
	margin: 0 -15px;
}
.ttm-boxes-spacing-20px {
	margin: 0 -15px;
	.ttm-box-col-wrapper {
		padding-right: 10px;
		padding-left: 10px;
		padding-bottom: 20px;
	}
}
.row.ttm-boxes-spacing-15px {
	margin: 0 -10px;
}
.ttm-boxes-spacing-15px {
	margin: 0 -10px;
	.ttm-box-col-wrapper {
		padding-right: 7.5px;
		padding-left: 7.5px;
		padding-bottom: 15px;
	}
}
.row.ttm-boxes-spacing-10px {
	margin: 0 -5px;
}
.ttm-boxes-spacing-10px {
	margin: 0 -5px;
	.ttm-box-col-wrapper {
		padding-right: 5px;
		padding-left: 5px;
		padding-bottom: 10px;
	}
}
.row.ttm-boxes-spacing-5px {
	margin: 0;
}
.ttm-boxes-spacing-5px {
	margin: 0;
	.ttm-box-col-wrapper {
		padding-right: 2.5px;
		padding-left: 2.5px;
		padding-bottom: 5px;
	}
}
.row.ttm-boxes-spacing-0px {
	margin: 0;
}
.ttm-boxes-spacing-0px {
	margin: 0;
	.ttm-box-col-wrapper {
		padding-right: 0px;
		padding-left: 0px;
		margin-bottom: 0px;
		outline: 0;
	}
}

/* ===============================================
    15.Pricing-Plan
------------------------*/
.ttm-pricing-plan {
	-webkit-border-radius: 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0;
    -moz-background-clip: padding;
    border-radius: 0;
    background-clip: padding-box;
    position: relative;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    padding: 40px 15px 5px;
    position: relative;
    overflow: hidden;
	@media (max-width: 991px){
		padding-top: 0;
	}
}
.ttm-pricing-plan:after {
    content: '';
    position: absolute;
    border-right: 1px solid #c4c4c4;
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
	@media (max-width: 767px) {
		content: unset;
	}
}
.ttm-pricing-plan:hover .ttm-p_table-main-icon {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
    opacity: 0;
}
.ttm-pricing-plan:hover .ttm-p_table-icon {
    animation: tm-bounce .5s ease-in-out 50ms;
}
.ttm-pricing-plan .ttm-p_table-main-icon {
    opacity: 1;
    -webkit-transition: all .5s ease-in-out 0s;
    -moz-transition: all .5s ease-in-out 0s;
    -ms-transition: all .5s ease-in-out 0s;
    -o-transition: all .5s ease-in-out 0s;
    transition: all .5s ease-in-out 0s;
}
.ttm-pricing-plan:hover .ttm-p_table-heading {
    transform: translateY(-80px);
    -webkit-transform: translateY(-80px);
}
.ttm-pricing-plan:hover .ttm-p_table-footer {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    opacity: 1;
}
.row > [class*='col-']:last-child >.ttm-pricing-plan:after{content: unset;}
.ttm-p_table-head {
	text-align: center;
    display: block;
    position: relative;
    background-color: #fff;
}
.ttm-p_table-icon {
	font-size: 75px;
    padding-bottom: 25px;
    color: #000;
}
.ttm-p_table-heading {
	opacity: 1;
    -webkit-transition: all .5s ease-in-out 0s;
    -moz-transition: all .5s ease-in-out 0s;
    -ms-transition: all .5s ease-in-out 0s;
    -o-transition: all .5s ease-in-out 0s;
    transition: all .5s ease-in-out 0s;
	.ttm-p_table-title h3 {
		font-size: 24px;
		line-height: 34px;
		margin-bottom: 0;
		font-weight: 700;
	}
}
.ttm-p_table-title {
	h3 {
		font-size: 33px;
		line-height: 50px;
		font-weight: 600;
	}
}
.ttm-p_table-amount {
	position: relative;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    vertical-align: top;
    margin-top: 15px;
.ttm-ptablebox-price {
	font-size: 36px;
    line-height: 46px;
    font-weight: 700;
    color: $theme-SkinColor;
}
	.cur_symbol {
		font-size: 36px;
		line-height: 46px;
		font-weight: 700;
		color: $theme-SkinColor;
	}
	.pac_frequency {
		position: relative;
		font-size: 20px;
		line-height: 30px;
		font-weight: 700;
		vertical-align: top;
		margin-top: 15px;
		color: #000;
		display: inline-block;
	}
}
.ttm-p_table-price {
	position: relative;
	display: inline-block;
	margin-top: 22px;
	font-size: 60px;
	font-weight: bold;
	font-family: "Amiri", sans-serif;
	line-height: 1;
	margin-bottom: 0;
	padding-left: 20px;
}
.ttm-p_table-body {
	.ttm-p_table-features {
		list-style-type: none;
		margin: 0;
		padding: 12px 0 20px 0;
	}
}
.ttm-p_table-features {
	li {
		padding: 0;
		margin: 0;
		position: relative;
		padding-top: 12px;
		border-bottom: 1px solid #e5e5e5;
		padding-bottom: 11px;
		padding-left: 25px;
		&:before {
			font-family: "FontAwesome";
			content: '\f00c';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			display: inline-block;
			text-decoration: inherit;
			left: 0;
			position: absolute;
		}
	}
}
.ttm-p_table-footer {
	display: block;
	text-align: center;
	opacity: 0;
    -webkit-transition: all .5s ease-in-out 0s;
    -moz-transition: all .5s ease-in-out 0s;
    -ms-transition: all .5s ease-in-out 0s;
    -o-transition: all .5s ease-in-out 0s;
    transition: all .5s ease-in-out 0s;
	margin-top: -30px;
	padding-top: 10px;
	.ttm-btn {
		padding: 12px 20px 13px;
		font-size: 14px;
		width: 100%;
	}
}
.ttm-pricing-plan.pricing-recommended-plan {
	box-shadow: 0 0 30px rgba(24,35,51,.06);
	z-index: 1;
	padding-bottom: 90px;
	background-color: #fff;
	overflow: visible;
	.ttm-p_table-features {
		li {
			border-bottom: 1px solid #eeeeee;
		}
	}
	.ttm-featured-title {
		position: absolute;
		top: -45px;
		left: 0;
		right: 0;
		text-align: center;
		font-weight: 700;
		font-size: 15px;
		width: 100%;
		color: #fff;
		padding: 10px 15px;
	}
}

/* pricing style1*/

.ttm-pricing-plan.style1 {
	padding: 0;
	.ttm-p_table-content {
		list-style: none;
		padding: 16px 20px 17px;
	}
	.ttm-p_table-heading {
		display: flex;
		justify-content: space-between;
		transition: .1s;
		a.ttm-btn {
			color: $theme-SkinColor;
			border-bottom: 1px solid;
			font-weight: 600;
			font-size: 16px;
			line-height: 26px;
			opacity: 0;
			position: absolute;
			right: 0;
			-webkit-transition: transform .5s ease-in-out;
			transition: transform .5s ease-in-out;
			-webkit-transform: translateX(0px);
			transform: translateX(0px);
		}
	}
	.ttm-p_table-title  {
		h3 {
		display: inline-block;
		font-size: 20px;
		line-height: 30px;
		font-weight: 600;
		margin-bottom: 0;
		}
		p {
			display: inline;
		}
	}
	.ttm-p_table-amount {
		margin-top: 0;
		position: unset;
	}
	span.ttm-ptablebox-price {
		display: inline-block;
		font-size: 20px;
		line-height: 20px;
		font-weight: 600;
		margin-bottom: 0;
		padding-right: 0;
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
		-webkit-transition: transform .5s ease-in-out;
		transition: transform .5s ease-in-out;
		color: $black;
	}
	li.ttm-p_table-content:nth-child(even) {
		background-color: $theme-GreyColor;
		border-radius: 8px;
	}
	.ttm-p_table-heading:hover {
		a.ttm-btn {
			opacity: 1;
			-webkit-transition: transform .5s ease-in-out;
			transition: transform .5s ease-in-out;
			-webkit-transform: translateX(-20px);
			transform: translateX(-20px);
			transition: .5s linear;
		}
		span.ttm-ptablebox-price {
		-webkit-transform: translateX(-100px);
		transform: translateX(-100px);
		-webkit-transition: transform .5s ease-in-out;
		transition: transform .5s ease-in-out;
		}
	}
}
.ttm-pricing-plan.style1:hover .ttm-p_table-heading {
	transform: unset;
	-webkit-transform: unset;
}
.ttm-pricing-plan.style1:after {
	content: unset;
}

/* ===============================================
    21.Sidebar
------------------------*/
form.search-form span.screen-reader-text select::-webkit-input-placeholder {
	color: #000;
}
.sidebar {
	.widget-area {
		.widget {
			position: relative;
			border-radius: 6px;
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0px;
			}
		}
		.widget.with-title {
			padding: 30px 30px;
			background-color: $theme-GreyColor;
		}
	}
	.content-area { 
		margin-top: 0;
		padding-top: 100px;
		padding-bottom: 70px;
	}
	.widget-area.sidebar-left { 
		margin-top: 0;
		padding-top: 100px;
		padding-bottom: 70px;
		@media (max-width: 991px){
			padding-top: 20px;
			order: 1;
    		width: 100%;
		}
		.widget-nav-menu {
			background-color: $theme-GreyColor;
			margin-bottom: 30px;
			padding: 30px;
			border-radius: 8px;
			ul li {
				padding: 17px 25px;
				margin-bottom: 10px;
				background-color: $theme-WhiteColor;
				border: 0;
				font-weight: 700;
				border-radius: 8px;
				width: 100%;
				color: $black;
			}
		}
		.image-box {
			.widget_img {
				img {
					border-radius: 8px 8px 0 0;
				}
			}
			.featured-imagebox-service.style2 {
				text-align: center;
				padding: 0 30px 10px;
				margin-top: 0px;
				border-radius: 0 0 8px 8px;
				.featured-image-box-wrapper {
					display: block;
				}
				.featured-thumbnail {
					overflow: visible;	
					img {
						margin-top: 40px;
						width: auto;
					}
				}
				.featured-content .featured-title h3 a:after {
					content: '';
					position: absolute;
					border-bottom: 2px solid #fff;
					width: 45%;
					top: auto;
					bottom: 0;
					left: 98px;
				}
			}
		}
		.widget-download {
			padding: 30px;
			background-color: $theme-DarkColor;
			h3.widget-title {
				color: $theme-WhiteColor;
			} 
			ul {
				margin-bottom: 30px;
				li {
				border: 1px solid rgba(255,255,255,.2);
				padding: 12px 25px;
				margin-bottom: 15px;
				border-radius: 8px;
				a {
					display: flex;
					justify-content: space-between;
					width: 100%;
					align-items: center;
				}
				h3 {
					display: inline;
					a {
					color: $white;
					font-weight: 700;
					}
				}
				i {
					color: $white;
				}
				}
			}
			.ttm-social-links-wrapper {
				.social-icons li {
					display: inline-block;
					border: none;
					z-index: 1;
					position: relative;
					margin: 0 2px;
					width: 0;
					padding: 0 40px 0 0;
				}
				ul {
					margin-bottom: 0;
					li {
						a {
							display: block;
							font-size: 12px;
							width: 35px;
							height: 35px;
							text-align: center;
							line-height: 35px;
							border: 1px solid rgba(255,255,255,.2);
							border-radius: 50%;
							transition: all .9s ease 0s;
							margin-right: 5px;
						}
						a:hover {
							background-color: $theme-SkinColor;
							color: $theme-WhiteColor;
							border: transparent;
							transition: all .5s ease 0s;
						}
					} 
				}
			}
		}
	}
	.widget-area.sidebar-right {
		margin-top: 0;
		padding-top: 100px;
		padding-bottom: 70px;
		@media (max-width: 991px){
			padding-top: 60px;
			padding-bottom: 0;
		}
		.ttm-service-classic-content {
			h2 {
				font-size: 36px;
				font-weight: 700;
				line-height: 46px;
				padding: 35px 0 0px;
			}
		}
		.featured-icon-box.style4 .ttm-iconbox {
			right: 180px;
		}
		.ttm-pricing-plan:after {
			content: unset;
		}
		.featured-icon-box.style12 {
			padding: 25px 20px 25px;
			@media (max-width: 991px) {
				padding-bottom: 0px;
			}
		}
		.featured-icon-box.style12:before{
			top: 45px;
			left: 57%;
		}
		.wrap-form label {
			font-size: 16px;
		}
		.ttm-fid.inside.style2 {
			padding: 35px 20px 40px;
			@media (max-width: 991px){
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
		.ttm-fid.style2 h4 span {
			font-size: 68px;
			line-height: 78px;
			@media (max-width: 991px){
				font-size: 45px;
				line-height: 55px;
			}
		}
		.ttm-fid.style2:after {
			height: 50px;
			top: 50px;
		}
	}
	.widget {
		a {
			font-weight: 400;
			font-size: 15px;
		}
		ul {
			margin: 0;
			padding: 0;
			>li {
				padding-bottom: 10px;
				padding-top: 10px;
				list-style: none;
			}
		}
	}
	.sep_holder {
		.sep_line {
			border-color: #ebebeb;
		}
	}
	.widget-title {
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 20px;
		font-weight: 700;
		position: relative;
	}
	.widget.widget-search {
		padding: 28px 30px 36px;
		border: none;
		background-color: $theme-SkinColor;
		h3 {
			margin-bottom: 15px;
			color: $theme-WhiteColor;
		}
		.search-form {
			border: 0;
			position: relative;
			label {
				display: block;
				margin: 0;
			}
			.btn[type="submit"] {
				content: "";
				position: absolute;
				top: 15px;
				right: 20px;
				padding: 0;
				z-index: 1;
				box-shadow: none;
				color: #fff;
				outline: 0;
				background-color: transparent;
				background-size: 16px 16px;
			}
		}
		.input-text {
			background-color: rgba(255,255,255,.1);
			border: none;
			border-radius: 8px;
			color: $theme-WhiteColor;
			padding: 16px 42px 16px 30px;
			width: 100%;
		}
	}
	.widget-area.ttm-col-bgcolor-yes {
		.widget.widget-search {
			padding: 0;
		}
	}
	.widget-search {
		.screen-reader-text {
			clip: rect(1px,1px,1px,1px);
			position: absolute !important;
			height: 1px;
			width: 1px;
			overflow: hidden;
		}
	}
}
.featured-imagebox-service.style2:hover .featured-thumbnail img {
    transform: unset;
}
ul.contact-widget-wrapper {
	li {
		border: 0 !important;
		padding-top: 0 !important;
		padding-bottom: 16px !important;
		padding-left: 35px !important;
		position: relative;
		padding-top: 0;
		font-size: 16px;
		padding-bottom: 17px;
		line-height: 23px;
		&:last-child {
			padding-bottom: 0 !important;
		}
		i {
			position: absolute;
			top: 3px;
			left: 0;
			font-size: 17px;
		}
	}
}
.widget-banner {
	position: unset;
	border-radius: 5px;
	overflow: hidden;
	h3 {
		font-size: 28px;
		line-height: 35px;
	}
	.ttm-btn {
		i {
			font-size: 13px;
			line-height: 0;
		}
	}
}
.widget-recent-post {
	ul {
		>li {
			padding: 16px 0;
			>a {
				display: table-cell;
				vertical-align: top;
				padding-right: 20px;
			}
			>.post-detail {
				display: table-cell;
				vertical-align: top;
			}
			&:last-child {
				padding-bottom: 5px;
			}
			.post-detail {
				a {
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin-bottom: 6px;
					font-weight: 700;
					color: $black;
				}
				a:hover {
					color: $theme-SkinColor;
				}
			}
			img {
				border-radius: 8px;
			}
			.post-date {
				display: block;
				font-size: 15px;
    			line-height: 25px;
			}
		}
		li {
			&:after {
				content: " ";
				display: table;
				clear: both;
			}
		}
	}
}
.widget.widget-download {
	ul {
		li {
			margin-bottom: 15px;
			display: table;
			width: 100%;
			height: 100%;
			border-radius: 5px;
			padding: 0 15px 15px;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				h3 {
					font-size: 18px;
					line-height: 23px;
					color: #fff;
					padding-top: 9px;
					margin-bottom: 0;
				}
			}
			span {
				display: block;
				width: 30px;
				height: 26px;
				background-color: #fff;
				color: #000;
				padding-left: 6px;
				font-size: 10px;
				font-weight: 700;
			}
		}
	}
}
.widget-Categories {
	ul {
		li {
			a {
				position: relative;
				display: inline-block;
				padding-left: 20px;
				&:before {
					position: absolute;
					content: "\f101";
					font-family: "FontAwesome";
					top: 0%;
					left: 0;
					font-size: 16px;
					line-height: 20px;
				}
			}
			padding: 12px 0 !important;
			position: relative;
			&:first-child {
				padding-top: 0 !important;
				span {
					top: 0;
				}
			}
			&:last-child {
				padding-bottom: 0 !important;
			}
		}
	}
	li {
		span {
			position: absolute;
			right: 0;
			top: 14px;
			width: 22px;
			height: 22px;
			line-height: 23px;
			text-align: center;
			border-radius: 50%;
			display: block;
			font-size: 11px;
			font-weight: 400;
			transition: all .5s;
		}
	}
}
.widget.widget-nav-menu {
	ul {
		li {
			margin: 0 0 10px;
			padding: 0;
			border: 0;
			position: relative;
			display: block;
			background-color: #fff;
			transition: all .4s;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				display: block;
				margin: 5px 0;
				position: relative;
				font-weight: 600;
				font-size: 16px;
				transition: all 0.3s linear;
			}
		}
	}
}
.widget-recent-post {
	ul.ttm-recent-post-list {
		>li {
			padding: 16px 0;
			>a {
				display: table-cell;
				vertical-align: top;
				padding-right: 10px;
				width: 95px;
			}
			>.post-detail {
				display: table-cell;
				vertical-align: top;
			}
			&:last-child {
				padding-bottom: 5px;
			}
			.post-detail {
				a {
					line-height: 21px;
					font-size: 15px;
					display: block;
					padding-bottom: 4px;
					font-weight: 600;
				}
			}
			img {
				display: block;
				width: 85px;
			}
			.post-date {
				display: block;
				font-size: 12px;
				line-height: 22px;
			}
		}
		li {
			&:after {
				content: " ";
				display: table;
				clear: both;
			}
		}
	}
}
.widget {
	.tagcloud {
		a {
			display: inline-block;
			padding: 7px 21px;
			margin: 0 4px 10px;
			text-transform: capitalize;
			border-radius: 3px;
			font-size: 14px !important;
			background-color: #fff;
			font-weight: 600;
			&:nth-last-child(-n+2) {
				margin-bottom: 5px;
			}
		}
		a:hover {
			background-color: $theme-SkinColor;
			color: $theme-WhiteColor;
		}
	}
}
.widget_text {
	p {
		span {
			background-color: $theme-SkinColor;
			border-radius: 6px;
			padding: 1px 7px 2px;
			color: $theme-WhiteColor;
		}
	}
}
 
/* ===============================================
    10. Page-Title-Row
------------------------*/
.ttm-page-title-row { 
    position: relative;
    display: block;
    padding-top: 220px;
    padding-bottom: 80px;
    z-index: 1;
	background-position: 50% 62%;
	.page-title-heading {
		h2 {
			font-weight: 600;
			font-size: 68px;
			line-height: 75px;
			margin-bottom: 5px;
		}
	}
	.breadcrumb-wrapper {
		span {
			a {
				font-weight: 600;
				font-size: 16px;
				line-height: 18px;
			}
		}
		span{
			color: $theme-SkinColor;
		}
	}
}
@media (max-width: 767px) {
	.ttm-page-title-row .page-title-heading h2 {
		font-size: 40px;
		line-height: 50px;
	}
}
.breadcrumb-wrapper span {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 25px;
}
@media all and (max-width: 1199px) {
	.ttm-page-title-row {
		padding-top: 80px;
	}
}


/* pagination */
.ttm-covershow-box.ttm-cover-styleone {
    min-height: 700px
}
.ttm-covershow-box.ttm-init {
    transition: opacity .5s .2s;
    opacity: 1
}
.ttm-covershow-box .covershow-holder {
    counter-reset: count;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden
}
.ttm-covershow-box .ttm-covershow-item.active {
    transform: translate3d(0,0,0)
}
.ttm-covershow-box .ttm-covershow-item:first-child {
    transform: none!important
}
.ttm-covershow-box.ttm-init .ttm-covershow-item {
    transition: all 1s
}
.ttm-covershow-box .ttm-covershow-item {
    counter-increment: count;
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    min-height: 100%;
    width: calc(100% - 6*80px);
    padding-top: 0;
    transform: translate3d(100%,0,0);
    background: #000
}
.ttm-covershow-box .ttm-covershow-item.active .ttm-cover-header {
    transition-delay: 0s;
    background-color: $theme-SkinColor;
    border-left: 0
}
.ttm-covershow-box .ttm-cover-header {
    position: absolute;
    top: 0;
    left: -80px;
    display: block;
    height: 100%;
    width: 80px;
    cursor: pointer;
    box-sizing: border-box;
    background: #000;
    border-left: 1px solid #282828;
    border-right: 1px solid transparent;
    transition: border-color .35s ease-out .7s
}
.ttm-covershow-box .ttm-cover-header .ttm-cover-title {
    transform: rotate(180deg);
    position: absolute;
    bottom: 50px;
    left: calc(50% - 15px);
    margin: 0;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed;
	
    color: $theme-WhiteColor;
}
.ttm-covershow-box .ttm-covershow-item.active .ttm-cover-title {
    background-position-y: 0
}
.ttm-covershow-box .ttm-covershow-item:first-child {
    transform: none!important
}
.ttm-covershow-box {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    opacity: 0
}
.ttm-cover-content.ttm-coverbox-predefined,.ttm-cover-content-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    overflow: hidden
}
.ttm-cover-content-inner img {
    width: 100%!important;
    height: 100%!important;
    object-fit: cover
}
.ttm-cover-styleone .ttm-cover-header .ttm-box-icon {
    font-size: 55px;
    padding-top: 50px;
	color: $theme-SkinColor;
    text-align: center
}
.ttm-cover-styleone .ttm-covershow-item.active .ttm-cover-header .ttm-box-icon {
    color: $theme-WhiteColor;
}

// cover-image

.ttm-covershow-box {
    transition: opacity .5s .2s;
    opacity: 1;
}
.ttm-covershow-box {
    min-height: 700px;
}
.ttm-covershow-box .covershow-holder {
    counter-reset: count;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
}
.ttm-covershow-box .ttm-covershow-item:first-child {
    transform: none!important;
}
.ttm-covershow-box .ttm-covershow-item {
    transition: all 1s;
}
.ttm-covershow-box .ttm-covershow-item {
    counter-increment: count;
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    min-height: 100%;
    width: calc(100% - 6*80px);
    padding-top: 0;
    transform: translate3d(100%,0,0);
    background: #000;
}
.ttm-covershow-box .ttm-cover-header {
    position: absolute;
    top: 0;
    left: -80px;
    display: block;
    height: 100%;
    width: 80px;
    cursor: pointer;
    box-sizing: border-box;
    background: #000;
    border-left: 1px solid #282828;
    border-right: 1px solid transparent;
    transition: border-color .35s ease-out .7s;
}
.ttm-covershow-box .ttm-cover-header .ttm-cover-title {
    transform: rotate(180deg);
    position: absolute;
    bottom: 50px;
    left: calc(50% - 15px);
    margin: 0;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    background-color: currentColor;
    background-size: 100% 200%;
    background-position-y: 100%;
    transition: background-position .8s cubic-bezier(.25,.1,.14,.91);
    color: #fff;
}
.ttm-covershow-box .ttm-cover-header .ttm-box-icon {
    font-size: 55px;
    padding-top: 50px;
    color: $theme-SkinColor;
    text-align: center;
}
.ttm-cover-content, .ttm-cover-content-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    overflow: hidden;
}
.ttm-cover-content-inner img {
    width: 100%!important;
    height: 100%!important;
    object-fit: cover;
}

/* pagination */

.pagination-block {
	span.page-numbers {
		width: 44px;
		height: 44px;
		line-height: 39px;
		text-align: center;
		display: inline-block;
		background-color: $theme-SkinColor;
		color: $theme-WhiteColor;
		font-size: 18px;
		font-weight: 600;
		border-radius: 8px;
		margin: 0 3px 10px;
		padding: 0;
		border: 2px solid;
		-webkit-transition: all .3s ease 0s;
		-moz-transition: all .3s ease 0s;
		-ms-transition: all .3s ease 0s;
		-o-transition: all .3s ease 0s;
		transition: all .3s ease 0s;
	}
	a.page-numbers {
		width: 44px;
		height: 44px;
		line-height: 39px;
		text-align: center;
		display: inline-block;
		background-color: $theme-WhiteColor;
		color: $theme-SkinColor;
		font-size: 18px;
		font-weight: 600;
		border-radius: 8px;
		margin: 0 3px 10px;
		padding: 0;
		border: 2px solid $black;
		-webkit-transition: all .3s ease 0s;
		-moz-transition: all .3s ease 0s;
		-ms-transition: all .3s ease 0s;
		-o-transition: all .3s ease 0s;
		transition: all .3s ease 0s;
	}
	a.next.page-numbers {
		padding: 0 30px 0 25px;
    	width: auto;
		height: 44px;
		line-height: 39px;
		text-align: center;
		display: inline-block;
		background-color: $theme-WhiteColor;
		color: $black;
		font-size: 18px;
		font-weight: 600;
		border-radius: 8px;
		margin: 0 3px 10px;
		border: 2px solid $black;
		-webkit-transition: all .3s ease 0s;
		-moz-transition: all .3s ease 0s;
		-ms-transition: all .3s ease 0s;
		-o-transition: all .3s ease 0s;
		transition: all .3s ease 0s;
	}
}

/* go-to-top */

.error-pg {
    margin: auto;
    text-align: center;
    height: 100vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 991px) { 
        text-align: center;        
    }

    .page-header {
        .page-title {
            font-size : 90px;
            line-height: 1;
        }
    }
    .page-content {
        p{
            font-size : 90px;
            line-height: 1;
            font-family: $bodyfont;
            color: $theme-DarkColor;
            font-weight: 600;
            margin-bottom: 50px;
        }
    }
    .error-type {
        text-align: center;
        margin-bottom: 40px;
        display: inline-flex;
        h2 {
            font-size : 300px;
            line-height: 1;
            margin: 0 -20px;
            @media only screen and (min-width: 1200px) {
                margin: 0;
            }
        }
        .error-icon {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            padding: 20px 20px;
            cursor: pointer;
            &:after {
                position: absolute;
                content: "";
                bottom: 0px;
                left: 0;
                right: 0;
                background-color: #f3f3f4;
                height: 44px;
                z-index: -1;
                border-radius: 50%;
            }
            img {
                transition: all 0.5s ease-in-out 0s;
                animation-duration: 2s;
                animation-iteration-count: infinite;
                transform-origin: bottom;
            }
        }
    }
}

// key frames
.bounce {
    animation-name: bounce;
    animation-duration: 4s;
    -moz-animation-duration: 4s;
}
@keyframes bounce {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-5px); }
    100% { transform: translateY(0); }
}
.Scrolltop{
    position: fixed;
    right: 12px;
    bottom: 10px;
    width: 43px;
    height: 43px;
    line-height: 43px;
    font-weight: 100;
    font-size: 16px;
    background-color: $theme-SkinColor;
    color: #fff;
    z-index: 1;
    text-align: center;
    border-radius: 8px;
    transition: all .5s ease-in-out 0s;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .10);
    
}
.ttm-header-style-02 .site-header-menu-inner {
    position: unset;
    right: 0px;
    top: 100px;
    padding: 0 30px;
    background-color: transparent;
    z-index: 10;
}

.whatsapp-container {
	position: fixed;
	z-index: 1;
	bottom: 40px;
	left: 42px;
	display: flex;
	align-items: center;
	transition: all .5s ease-in-out 0s;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-transform-origin: bottom;
	transform-origin: bottom;

	.whatsapp-icon {
	  	width: 50px;
	  	height: 50px;
	  	margin-right: 10px;
		transition: all .5s ease-in-out 0s;
		transform-origin: bottom;
	  	-webkit-animation-duration: 2s;
	  	animation-duration: 2s;
	  	-webkit-animation-iteration-count: infinite;
	  	animation-iteration-count: infinite;
	  	-webkit-transform-origin: bottom;
  
		&:hover + .wsp-options {
			visibility: visible;
			opacity: 1;
			transition: opacity .5s ease-in-out;
	  	}
	}
  
	.wsp-options {
		display: flex;
		visibility: hidden;
		opacity: 0;
	  	flex-direction: column;
		transition: opacity .5s ease-in-out;
		&:hover{
			visibility: visible;
			opacity: 1;
		}
	}
  
	.wsp-option {
	  	background-color: $theme-SkinColor;
	  	margin-bottom: 10px;
	  	text-decoration: none;
	  	color: #fff;
	  	border-radius: 8px 8px 8px 0;
	  	padding: 1px 10px;
		&:nth-child(2){
			border-radius: 0 8px 8px 8px;
		}
	}

	@media only screen and (max-width: 570px) {
		bottom: 20px;
		left: 22px;
	}
}

.map-location{
	img{
		margin-top: 20px;
		margin-left: 20px;
		margin-bottom: 20px;
	}
}

.banner-btn{
	margin-right: 115px;
	@media only screen and (max-width: 768px) {
		margin-right: 0;
	}
}