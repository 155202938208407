.banner_slider.slick-slider {
	.slick-prev {
		opacity: 0;
		visibility: hidden;
		width: 40px;
		height: 40px;
		top: 60%;
		line-height: 40px;
		padding-right: 3px;
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		display: block;
		z-index: 1;
		margin: 0 0;
		background: rgba(34, 54, 69, 0.5);
		border-radius: 50%;
		&:hover {
			background: #000;
		}
		&:before {
			font-family: 'Font Awesome 5 Free';
			font-size: 14px;
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
			opacity: 1;
			color: #fff;
			font-weight: bold;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f053";
		}
	}
	.slick-next {
		opacity: 0;
		visibility: hidden;
		width: 40px;
		height: 40px;
		line-height: 40px;
		padding-right: 3px;
		text-align: center;
		position: absolute;
		top: 60%;
		left: 0;
		right: 0;
		display: block;
		z-index: 1;
		margin: 0 0;
		background: rgba(34, 54, 69, 0.5);
		border-radius: 50%;
		right: 0;
		left: auto;
		padding-right: 0;
		padding-left: 3px;
		&:hover {
			background: #000;
		}
		&:before {
			font-family: 'Font Awesome 5 Free';
			font-size: 14px;
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
			opacity: 1;
			color: #fff;
			font-weight: bold;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f054";
		}
	}
	&:hover {
		.slick-prev {
			opacity: 1;
			margin: 0 20px;
			visibility: visible;
		}
		.slick-next {
			opacity: 1;
			margin: 0 20px;
			visibility: visible;
		}
	}
}
.slide {
	height: 750px;
	align-items: center;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;
	.slide__img {
		width: 100%;
		height: auto;
		overflow: hidden;
		position: absolute;
		left: 0;
		img {
			opacity: 1;
			-webkit-animation-duration: 3s;
			animation-duration: 3s;
			transition: all 1s ease;
		}
	}
	.slide__content {
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.slide-icon-content {
		h3 {
		font-weight: 600;
    	font-size: 18px;
		line-height: 28px;
		padding-left: 70px;
		margin-bottom: 0;
		}
		.slide-example {
			a {
			font-size: 15px;
			line-height: 25px;
			}
		}
	}
	.slide__content--headings {
		padding: 135px 0 0;
		position: relative;
		transition: all .4s;
		h2 {
			font-size: 90px;
			line-height: 100px;
			text-align: left;
			margin-bottom: 10px;
		}
		p {
			font-size: 16px;
			line-height: 26px;
			margin-bottom: 0;
			font-weight: 400;
			color: rgba(255,255,255,.71);
		}
		.slider-icon {
			margin-top: 30px;
			.slide-icon-content {
				display: inline-block;
				top: 15px;
				position: relative;
			}
			.ttm-icon i {
				left: 60px;
				top: 35px;
			}
			h3 {
				font-size: 24px;
				line-height: 34px;
				padding-left: 55px;
			}
			p {
				color: $black;
				padding-left: 55px;
			}
		}
	}
	.slider-content-head-3 {
		text-align: left;
		padding: 5px 25px;
		h3 {
		font-size: 36px;
		line-height: 46px;
		display: inline-block;
		}
		p {
			color: $white;
			display: inline-block;
			font-size: 20px;
			line-height: 30px;
			font-weight: 600;
		}
	}
	span.webkit-content-main {
		color: $theme-SkinColor;
		font-family:"Caveat", Arial, Helvetica, sans-serif;
	}
	.slide__content--headings > div h2.bg-circle:before {
		position: absolute;
		content: '';
		background-color: $theme-SkinColor;
		color: $white;
		width: 125px;
		height: 125px;
		min-height: 0px;
		min-width: 0px;
		left: 0px;
		overflow: hidden;
		border-radius: 100px;
		transform-origin: 50% 50%;
		opacity: 1;
		transform: translate(0px, 0px);
		visibility: visible;
	}
	.slide__content--headings:hover > div h2.bg-circle:before {
		background-color: $theme-DarkColor;
	}
	.heading-icon-3 {
		color: $theme-SkinColor;
		margin-top: 50px;
		font-size: 40px;
		.slide-icon-content {
			display: inline-block;
			padding-right: 30px;
			h3 {
				font-size: 20px;
				padding-left: 15px;
				top: -10px;
				position: relative;
				color: $white;
			}
		}
	}
}
.banner_slider_3 {
	.slide .slide__content--headings {
		padding: 230px 0 0;
		.fade-main {
			top: 150px;
		}
	}
}
.slide-icon-content {
	h3 {
		a:hover {
			color: $theme-WhiteColor;
		}
	}
}



/* /////////// IMAGE ZOOM /////////// */
.banner_slider .slide_img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}
.slide__content--headings .fade-main {
    position: absolute;
    content: "";
    height: 55px;
    width: 23%;
    right: 0px;
    left: auto;
    top: 30px;
    background-color: #097ae9;
}
.slick-active .slide_img {
    -webkit-animation-delay: 24s;
    -moz-animation-delay: 24s;
    -o-animation-delay: 24s;
    -ms-animation-delay: 24s;
    animation-delay: 24s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: imageAnimation 36s linear 0s;
    -moz-animation: imageAnimation 36s linear 0s;
    -o-animation: imageAnimation 36s linear 0s;
    -ms-animation: imageAnimation 36s linear 0s;
    animation: imageAnimation 36s linear 0s;
}
@keyframes imageAnimation { 
  0% { animation-timing-function: ease-in; }
  8% { transform: scale(1.02); animation-timing-function: ease-out; }
  17% { transform: scale(1.04); animation-timing-function: ease-out; }
  22% { transform: scale(1.02); } 25% { -transform: scale(1.1); }
}

/* /////////// RESPONSIVE /////////// */

@media only screen and (max-width: 1199px){
	.banner_slider {
		.slide_img {
			height: 530px;
		}
	} 
	.slide {
		height: 530px;
		.slide__content--headings {
			padding: 70px 0 0;
			display: block;
		}
		.slide__content {
			display: block;
		}
		.slide__content--headings h2 {
			font-size: 80px;
			line-height: 90px;
		}
	}
}
.banner_slider_2 {
	.slide .slide__content--headings > div h2.bg-circle:before {
		width: 110px;
		height: 110px;
	}
	.slide__content--headings .slider-icon .ttm-icon i {
		left: 56px;
		top: 28px;
		transition: all 0.4s ease-in-out;
	}
	.slide__content--headings .slider-icon .ttm-icon i:hover {
		transform: rotate(45deg);
		transition: all 0.4s ease-in-out;
		left: 20px;
		top:-10px;
		color: $theme-WhiteColor;
	}
}
.banner_slider_3 {
	.slide .slider-content-head-3 h3 {
		font-size: 25px;
	}
	.slide .slider-content-head-3 p {
		font-size: 15px;
	}
}

@media only screen and (max-width: 991px){
	.banner_slider_3 {
		.slide .slider-content-head-3 h3 {
			font-size: 23px;
		}
		.slide .slider-content-head-3 p {
			font-size: 14px;
		}
		.slide__content--headings .fade-main {
			display: none;
		}
	}
} 

@media only screen and (max-width: 767px){
	.slide  {
		height: 430px;
		.slide__content--headings {
			padding: 65px 0 0;
			h2 {
				font-size: 57px;
				line-height: 67px;
				text-align: center;
			}
			.ttm-icon {
				display: none;
			}
			.banner-btn {
				text-align: center !important;
			}
		}
	}
	.slick_slider .banner_slider_2 {
		.slide-icon-content {
			display: none;
		}
	}
	.banner_slider_2 .slide .slide__content--headings {
		.slider-icon .slide-icon-content {
			display: none;
		}
	}
	.banner_slider_2 {
		.slide .slide__content--headings > div h2.bg-circle:before {
			content: unset;
		}
		.slide .slide__content--headings {
			padding: 115px 0 0;
		}
	}
	.banner_slider_3 {
		.slide .slide__content--headings {
			padding: 150px 0 0;
		}
		.slide__content--headings .banner-3-button {
			text-align: center !important;
		}
	}
}

@media only screen and (max-width: 575px) {
	.slide  {
		height: 330px;
		.slide__content--headings {
			padding: 55px 0 0;
			h2 {
				font-size: 43px;
				line-height: 43px;
				text-align: center;
			}
		}
	}
}